<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">
      <prototype-header></prototype-header>
      <div class="row">
        <div class="col pe-0">
          <div class="card">
            <div class="card-body">
              <h3>Feed</h3>
              <p>
                7 new matches.
                <a href="javascript:void(0);">[Refresh Search List]</a>
                <a href="javascript:void(0);" @click.prevent="restartFeed" class="float-end">[Refresh Feed]</a>
              </p>
              
              <div>
                <vuevectormap
                  ref="map"
                  width="100%"
                  height="400"
                  :options="mapOptions"
                  @loaded="mapLoaded"
                >
                </vuevectormap>
              </div>

              <div class="timeline-alt my-4 py-0">
                <div class="timeline-item" v-for="(item, index) in feed" :class="{ live: item.live }" :key="item.id">
                  <i class="mdi timeline-icon" :class="getInsightIconClasses(item)"></i>
                    <div class="timeline-item-info">
                        <router-link class="fw-bold d-block" :class="getInsightClass(item)" :to="{ name: 'prototype_contact_view', params: { id: item.contact }}">{{ item.contact_name }}</router-link>
                        <span class="fw-bold mb-1">
                          <a href="javascript:void(0);" class="text-muted">{{ item.title }}</a>
                        </span>
                        <br>
                        {{ item.content || item.details || item.title }}
                        <span class="small" v-if="item.link">
                          [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                        </span>
                        <p class="mb-0 pb-2">
                          <small class="text-success fw-bold" v-if="index < 7">
                            Match on
                            <template v-if="index < 3">industry tags</template>
                            <template v-else>description</template>
                          </small>
                          <br>
                          <small class="text-muted" v-if="item.timestamp">{{ $filters.dateformat(item.timestamp) }}</small>
                          <small class="text-muted" v-else>Recently</small>
                        </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Prototype/AppHeader.vue'
import Footer from '@/components/Layout/Footer.vue'

import PrototypeHeader from '@/components/Prototype/Header.vue'

export default {
  name: 'account',

  components: {
    'app-header': Header,
    'app-footer': Footer,
    'prototype-header': PrototypeHeader,
  },

  data() {
    return {
      feed: [],
      map: null,
      mapOptions: {
        zoomOnScroll: false,
        markers: [
          // { name: "Alex Bogdashin tweeted: Proptech is the future", coords: [55, 35] },
          // { name: "Shawn Lewis posted: Is Proptech Defining The Future of Real Estate?", coords: [56, -106] },
          // { name: "Bruno Loreto tweeted: Vestate is a utilitarian project in its core. A #proptech platform for all!", coords: [-14.235, -51.9253] }
        ],
        markerStyle: {
          initial: { fill: "#ff4551" },
        },
        regionStyle: {
          selected: {
            fill: "blue",
            opacity: 0.25,
          },
        },
        onMarkerClick(event, index) {
          const that = window.prototypeFeed
          const item = that.liveEvents[index]
          console.log('event', item)
          that.$router.push({ name: 'prototype_contact_view', params: { id: item.contact } })
        },
        onMarkerTooltipShow(event, tooltip, code) {
          const that = window.prototypeFeed
          const item = that.liveEvents[code]
          const icon = `<i class="mdi text-white ${that.getMapInsightIconClasses(item)}"></i>`
          tooltip.text(
            `<p class="mb-0" style="max-width: 350px; white-space: break-spaces;">${icon} ${tooltip.text()}</p>`,
            true // Enables HTML
          )
        }
      },
      liveTimer: null,
      liveEvents: [
        {
          "id": 9000001,
          "contact": 33102,
          "contact_name": "Companyon Ventures",
          "title": "Twitter",
          "type": "Twitter",
          "details": "Companyon Ventures reposted: announce our $5M Seed round co-led by @CompanyonVC and @Epic_Ventures to accelerate our mission to democratize earning capital through live experiences.",
          "link": "https://twitter.com/poshvip_/status/1651581759681642500", 
          // 3 hours ago
          "timestamp": new Date(new Date().getTime() - 3 * 60 * 60 * 1000).toISOString(),
          "live": true,
          "marker": {
            "name": "Companyon Ventures reposted: announce our $5M Seed round co-led by @CompanyonVC and @Epic_Ventures to accelerate our mission to democratize earning capital through live experiences.",
            "coords": [42.3601, -71.0589],
            "style": { fill: "#182B49" },
          }
        },
        {
          "id": 9000002,
          "contact": 50636,
          "contact_name": "Unicorn Growth Capital",
          "title": "Twitter",
          "type": "Twitter",
          "details": "Unicorn Growth Capital:\ndiscussing the next generation of FinTech opportunities with leading FinTech investors and founders.",
          "link": "https://twitter.com/UnicornGrowth_C/status/1612601431382712322", 
          "timestamp": new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
          "live": true,
          "marker": {
            "name": "Unicorn Growth Capital tweeted: discussing the next generation of FinTech opportunities with leading FinTech investors and founders.",
            "coords": [6.5244, 3.3792],
            "style": { fill: "#182B49" },
          }
        },
        {
          "id": 9000003,
          "contact": 3955,
          "contact_name": "Gabriela Macagni",
          "title": "Podcast",
          "type": "Podcast",
          "details": "Nuevo episodio de @FundadoresPodcast ya está al Aire! ¡🗣️🎙️ Hace una semanas tuve la oportunidad de grabar con @Alex Gálvez para hablar sobre la historia de @Matterscaleventures y los primeros pasos de @Endeavor mientras abríamos oficinas por el mundo. Se los recomiendo para aprender de: 🚀 Emprendimientos de alto impacto. 🤝 El apoyo, seguimiento y la empatía que necesitas en tus inversionistas. 🌍 Industries that matter and companies that Scale 😄 Denle like y reaccionen para llegar para que logremos impactar y escalar a su mejor versión el ecosistema de LATAM 🗓️",
          "link": "https://www.linkedin.com/posts/matterscale_podcast-venturecapital-latamstartups-activity-7046835093335670784-Afjc",
          timestamp: new Date(new Date().getTime() - 18 * 60 * 60 * 1000).toISOString(),
          live: true,
          marker: {
            "name": "Gabriela Macagni: Nuevo episodio de @FundadoresPodcast ya está al Aire! ¡🗣️🎙️ Hace una semanas tuve la oportunidad de grabar con @Alex Gálvez para hablar sobre la historia de @Matterscaleventures y los primeros pasos de @Endeavor mientras abríamos oficinas por el mundo. Se los recomiendo para aprender de: 🚀 Emprendimientos de alto impacto. 🤝 El apoyo, seguimiento y la empatía que necesitas en tus inversionistas. 🌍 Industries that matter and companies that Scale 😄 Denle like y reaccionen para llegar para que logremos impactar y escalar a su mejor versión el ecosistema de LATAM 🗓️",
            "coords": [-34.6037, -58.3816],
            "style": { fill: "#fa5c7c" },
          }
        },
        {
          id: 9000004,
          contact: 50887,
          contact_name: "360 Capital",
          title: "Twitter: It's time to build, but greener",
          type: 'Twitter',
          details: 'Meeting with more and more entrepreneurs tackling the CO2 emissions of the Built Environment in Europe.',
          link: 'https://twitter.com/360CP/status/1702300362466631876',
          timestamp: new Date(new Date().getTime() - 48 * 60 * 60 * 1000).toISOString(),
          live: true,
          marker: {
            name: "360 Capital tweeted: Meeting with more and more entrepreneurs tackling the CO2 emissions of the Built Environment in Europe.",
            coords: [48.8566, 2.3522],
            style: { fill: "#182B49" },
          }
        },
        {
            "id": 9000005,
            "contact": 3964,
            "contact_name": "Mark Cuban",
            "title": "Podcast",
            "type": "Podcast",
            "details": "The Objective Basketball Podcast is BACK! Es and Lauren discuss the new ownership change of the Dallas Mavericks and dive into what it might mean for the future of the franchise and its intrepid owner Mark Cuban (4:15). Plus, they give updates on the Raptors and Mavs and dive into the in-season tournament (21:00).",
            "link": "https://open.spotify.com/show/3oKVEIY",
            timestamp: new Date(new Date().getTime() - 36 * 60 * 60 * 1000).toISOString(),
            live: true,
            marker: {
              "name": "Mark Cuban: The Objective Basketball Podcast is BACK! Es and Lauren discuss the new ownership change of the Dallas Mavericks and dive into what it might mean for the future of the franchise and its intrepid owner Mark Cuban (4:15). Plus, they give updates on the Raptors and Mavs and dive into the in-season tournament (21:00).",
              "coords": [32.7767, -96.7970],
              "style": { fill: "#fa5c7c" },
            }
        },
        {
          "id": 9000006,
          "contact": 4822,
          "contact_name": "David Lin",
          "title": "LinkedIn Post",
          "type": "LinkedIn Post",
          "details": "Only two days left until the PEI Awards 2024 voting concludes! Primavera Capital is proud to be nominated for \"Fundraise of the Year in Asia\" as well as \"Firm of the Year in China\". We are humbled by these nominations and grateful for your continued support. Please cast your vote for Primavera Capital before the deadline on January 5, 2024 (Friday): https://lnkd.in/gmWDriHu. Your vote matters! “Primavera Capital Fund IV, which will focus across Asia-Pacific and cross-border, closed on $4.1 billion in February against a $4 billion target.” Private Equity International",
          "link": "https://uk.linkedin.com/showcase/private-equity-international-pe/?trk=organization_guest_main-feed-card-text",
          timestamp: new Date(new Date().getTime() - 24 * 5 * 60 * 60 * 1000).toISOString(),
          live: true,
          marker: {
            "name": "David Lin: Only two days left until the PEI Awards 2024 voting concludes! Primavera Capital is proud to be nominated for \"Fundraise of the Year in Asia\" as well as \"Firm of the Year in China\". We are humbled by these nominations and grateful for your continued support. Please cast your vote for Primavera Capital before the deadline on January 5, 2024 (Friday): https://lnkd.in/gmWDriHu. Your vote matters! “Primavera Capital Fund IV, which will focus across Asia-Pacific and cross-border, closed on $4.1 billion in February against a $4 billion target.” Private Equity International",
            "coords": [39.9042, 116.4074],
            "style": { fill: "#39afd1" },
          }
        }
      ],
      liveEventsIndex: 0,
    }
  },

  computed: {
    ...mapState({
      stateLiveEventsIndex: state => state.prototype.eventIndex,
    })
  },
  
  mounted() {
    window.scrollTo(0, 0)
    window.prototypeFeed = this

    this.$store.dispatch('LOAD_INSIGHTS_FEED')
      .then((response) => {
        this.feed = response.data
        console.log('feed', this.feed)
        this.startLiveFeed()
      })
  },

  beforeUnmount() {
    if (this.liveTimer) {
      clearTimeout(this.liveTimer)
    }
    window.prototypeFeed = null
    document.querySelectorAll('.jvm-tooltip').forEach((el) => {
      el.remove()
    })
  },

  methods: {

    async startLiveFeed() {
      this.liveEventsIndex = this.stateLiveEventsIndex
      const events = this.liveEvents.slice(0, this.liveEventsIndex).map((event) => {
        event.live = false
        return event
      })
      console.log('startLiveFeed', this.liveEventsIndex)
      this.map.addMarkers(events.map((event) => event.marker))
      this.feed = [...events, ...this.feed]
      this.liveFeed()
    },

    liveFeed() {
      // random timeout between 3 and 30 seconds
      const timeout = Math.floor(Math.random() * (30000 - 3000 + 1) + 3000)
      this.liveTimer = setTimeout(() => {
        console.log('liveTimer', this.liveEventsIndex)
        const event = this.liveEvents[this.liveEventsIndex]
        if (!event) return
        this.map.addMarkers([event.marker])
        this.feed = [event, ...this.feed]
        this.liveEventsIndex++
        this.$store.commit('SET_PROTOTYPE_EVENT_INDEX', this.liveEventsIndex)
        if (this.liveEventsIndex < this.liveEvents.length) {
          this.liveFeed()
        }
      }, timeout)
    },

    mapLoaded(map) {
      this.map = map
    },

    restartFeed() {
      this.$store.commit('SET_PROTOTYPE_EVENT_INDEX', 0)
      window.location.reload()
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },

    getInsightTopic(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'LinkedIn Post'
        }
        if (item.type.includes('Podcast')) {
          return 'Podcast'
        }
      }
      return 'Webpage'
    },

    getInsightClass(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger'
        }
        if (item.type.includes('Newsletter')) {
          return 'text-success'
        }
        if (item.type.includes('Twitter')) {
          return 'text-primary'
        }
      }
      return 'text-default'
    },

    getInsightIconClasses(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info bg-info-lighten mdi-linkedin'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger bg-danger-lighten mdi-podcast'
        }
        if (item.type.includes('Newsletter')) {
          return 'text-success bg-success-lighten mdi-gmail'
        }
        if (item.type.includes('Twitter')) {
          return 'text-primary bg-primary-lighten mdi-twitter'
        }
      }
      return 'text-default bg-primary-lighten mdi-web'
    },

    getMapInsightIconClasses(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'mdi-linkedin'
        }
        if (item.type.includes('Podcast')) {
          return 'mdi-podcast'
        }
        if (item.type.includes('Newsletter')) {
          return 'mdi-gmail'
        }
        if (item.type.includes('Twitter')) {
          return 'mdi-twitter'
        }
      }
      return 'mdi-web'
    }

  }
}
</script>

<style lang="scss">
.timeline-item.live {
  animation: glow 0.5s;
}
#jvm-markers-group {
  circle {
    animation: glow 0.5s;
  }
}
</style>