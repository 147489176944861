<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">
      <prototype-header></prototype-header>
      <div class="wrapper">
        <div class="content-page">
          <div class="content">

            <div class="row">          
              <div class="card">
                <div class="card-body">
                  <div class="row">
                      <div class="col-12">
                        
                      <ul class="nav nav-tabs" v-if="matchFields && matchFields.length">
                        <li class="nav-item" v-for="group in matchFields">
                          <a
                            href="#"
                            class="nav-link"
                            :class="{ active: matchGroup === group.name }"
                            @click.prevent="addMatchFilters(group.name)"
                          >
                            {{ group.name }}
                          </a>
                        </li>
                      </ul>

                      <div class="table-data">
                        <div class="table-container">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th class="orderable" style="width: 15%">
                                  Full name
                                </th>
                                <th class="orderable" style="width: 15%">
                                  Company
                                </th>
                                <th style="width: 35%">
                                  Insights
                                </th>
                                <th style="width: 35%">
                                  Email Opener
                                </th>
                              </tr>
                            </thead>

                            <tbody v-if="contacts && contacts.results">
                              <tr v-for="(contact, index) in contacts.results" :key="contact.id">
                                <td>
                                  <router-link :to="`/pro/contacts/${contact.id}/`">{{ contact.first_name }} {{ contact.last_name }}</router-link>
                                  <span class="mx-2">
                                    <i class="uil uil-user text-green" v-if="contact.met_in_person"></i>
                                    <i class="uil uil-phone text-green" v-if="contact.over_call"></i>
                                    <i class="uil uil-envelope text-green" v-if="contact.over_email"></i>
                                  </span>
                                  <p class="small text-muted">
                                    {{ contact.email || '—' }}
                                  </p>
                                  <p v-if="index < 2">
                                    <span class="badge badge-warning-lighten">Contacted</span>
                                    <span class="badge badge-success-lighten ms-2">Replied</span>
                                  </p>
                                  <p v-else-if="index < 10">
                                    <span class="badge badge-warning-lighten">Contacted</span>
                                  </p>
                                </td>
                                <td>
                                  {{ contact.company.name || '—' }}
                                </td>
                                <td style="width: 35%; word-break: break-all;">
                                  <div class="py-2" v-if="contact.insights_loading">
                                    <loader size="48px"></loader>
                                  </div>
                                  <div v-if="Array.isArray(contact.insights) && contact.insights.length">
                                    <ul>
                                      <li v-for="item in contact.insights">
                                        {{ item.content }}<br>
                                        <span class="small" v-if="item.link">
                                          [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div v-else-if="!contact.insights_loading">
                                    --
                                  </div>
                                </td>
                                <td style="width: 35%">
                                  {{ getFakeOpener() }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          
                          <pagination
                            :items="contacts"
                            :currentPage="page"
                            :perPage="perPage"
                            @pagechanged="onPageChanged"
                          >
                          </pagination>

                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- end containt-page -->
        </div>
      
        <div class="action-footer">
          <div class="container-fluid">
            <div class="card mb-0">
              <div class="card-body">
                <div class="float-end">
                  <!-- <button class="btn btn-outline-primary me-4" :class="{ disabled: isSubmitting }" @click.prevent="goGetInsights">
                    <loader v-if="isSubmitting" size="20px" class="d-inline"></loader>
                    <i v-else class="uil uil-atom"></i>
                    Get Contact Insights
                  </button>

                  <span class="me-4">|</span> -->

                  <span class="me-2">Save search:</span>

                  <div class="form-group d-inline me-4">
                    <input type="text" v-model="searchName" placeholder="Search name">
                  </div>

                  <button class="btn btn-primary" :class="{ disabled: isSubmitting || isLoading }" @click.prevent="saveSearch">
                    <i class="uil uil-notebooks"></i>
                    Save Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'

import Header from '@/components/Prototype/AppHeader.vue'
import Footer from '@/components/Layout/Footer.vue'

import PrototypeHeader from '@/components/Prototype/Header.vue'

export default {
  name: 'contacts',

  mixins: [SearchMixin],

  components: {
    'app-header': Header,
    'app-footer': Footer,
    'prototype-header': PrototypeHeader,
  },

  data() {
    return {
      page: 1,
      perPage: 20,
      isSubmitting: false,
      pollTimer: null,
      module: 'contacts',
    }
  },

  computed: {
    loadingInsights() {
      return this.contacts && this.contacts.results && this.contacts.results.some((x) => x.insights_loading)
    }
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {

    onPageChanged(pageNumber) {
      this.page = pageNumber
      this.doSearch()
    },

    goGetInsights() {
      this.isSubmitting = true
      this.doSearch({ update_contact_insights: true })
    },

    saveSearch() {
      if (!this.startup) { return }
      const query = this.makeQuery()
      const search = {
        search_request_data: query,
        startup_id: this.startup.id,
        default_exclude: this.default_exclude || false,
        hide_ring_fence: this.hide_ring_fence || false,
        hide_undeliverable_contact: this.hide_undeliverable_contact || false,
        save_search_name: this.searchName || 'Untitled',
        number_of_investors: this.companies.count,
        number_of_contacts: this.contacts.count
      }
      this.isSubmitting = true
      this.$store.dispatch('SAVE_SEARCH', { form: search, startupId: this.startup.id }).then((response) => {
        this.$store.commit('SET_CURRENT_SEARCH', response.data)
        this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id }).then(() => {
          this.$router.push({ name: 'investors_searches' })
        })
      }).finally(() => {
        this.isSubmitting = false
      })
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },

    getFakeOpener() {
      const openers = [
        "I recently saw on Pitchbook that Marquee Invest prefers to invest in companies operating in the real estate development, engineering, construction, sports, entertainment, industry, international trade, technology, and education sectors. As a startup in the construction and water industries, Waisense provides an innovative solution that fights climate change by reducing water consumption and improving quality of life.",
        "I recently saw a post shared by Collider Ventures that highlights their focus on partnering with determined entrepreneurs to disrupt industries. As a startup founder, I believe Collider Ventures' investment philosophy aligns perfectly with Waisense's mission to combat climate change and improve water consumption.",
        "I came across a recent post by Maniv Mobility, and it seems that one of their areas of interest is in innovative technologies that tackle environmental and sustainability issues. With that in mind, I wanted to share with you our startup, Waisense, which has developed an internationally patented technology to save water in various applications, fighting climate change and improving quality of life. We believe that our solution aligns well with Maniv Mobility's focus on clean tech and could be a great opportunity for investment.",
        "I came across your venture capital firm, Algebra Ventures, and was intrigued by your focus on early-stage technology companies in Egypt. As the founder of Waisense, a company that has developed an internationally patented technology to save water in various applications, I believe our mission aligns with your passion for positive tech impact in the MEA region.",
        "I recently saw Muna Easa Al Gurg's post about advocating for women in business, and I couldn't help but notice the synergy between our startup, Waisense, and your firm's focus on logistics, transportation, and customer service. Waisense offers an internationally patented technology to save water and fight climate change, making it an innovative solution that aligns with the need for sustainability in various industries.",
        "I recently saw your company, MoreVC, invest in Israeli technology start-ups that are making a difference. With that in mind, I wanted to reach out and introduce Waisense, a company that has developed a proprietary internationally patented technology to save water in various applications such as real estate, construction, smart cities, and more. With a track record of success and a commitment to fighting climate change, Waisense presents an investment opportunity to be a part of the change for future generations.",
        "I noticed your recent post about the Angels Foundation of former Amdocs completing six investments, and I wanted to reach out because I believe our startup, Waisense, aligns perfectly with your investment interests. We have developed a proprietary technology to save water in various applications and are looking to scale up our sales and expand into new verticals. With our international patent and track record in the water and monitoring industries, we offer a unique investment opportunity to be part of the change for future generations.",
        "I noticed your recent investment in Keyper as part of your Seed VC, and I wanted to introduce you to Waisense, a startup with an internationally patented technology that saves water through their proprietary solution. With Waisense's focus on clean tech and smart cities, it aligns well with the growing and non-volatile markets you're already invested in.",
        "I noticed your recent post about the Angels Foundation of former Amdocs completing six investments, and I wanted to reach out because I believe our startup, Waisense, aligns perfectly with your investment interests. We have developed a proprietary technology to save water in various applications and are looking to scale up our sales and expand into new verticals. With our international patent and track record in the water and monitoring industries, we offer a unique investment opportunity to be part of the change for future generations.",
        "I recently saw your post about the importance of investing in clean technology and sustainability, and I couldn't help but think of Waisense. Waisense is an internationally patented technology developed by the experienced and committed team at Metrica6 that saves water in various applications. With its modularity and adaptability, Waisense presents an opportunity to be part of the change for future generations in growing markets such as Real Estate, Sustainable Construction, Water, and Smart-City.",
        "I noticed your firm, Firstime Venture Capital, invests in new digital companies and provides financial support. I wanted to introduce you to Waisense, a startup that has developed a proprietary internationally patented technology to save water in different applications. With its modularity and adaptability, Waisense offers a customizable solution for reducing water consumption and improving sustainability in growing markets like Real Estate, Sustainable Construction, and Smart-City.",
      ]
      return openers[Math.floor(Math.random() * openers.length)]
    }

  }

}
</script>
