import { format, formatDistance, subDays } from 'date-fns'

import pluralize from 'pluralize'
import unslugify from "unslugify"


const filters = {
  /**
   * Changes value to past tense.
   * Simple filter does not support irregular verbs such as eat-ate, fly-flew, etc.
   * http://jsfiddle.net/bryan_k/0xczme2r/
   *
   * @param {String} value The value string.
   */
  'past-tense': function (value) {
    // Slightly follows http://www.oxforddictionaries.com/us/words/verb-tenses-adding-ed-and-ing
    var vowels = ['a', 'e', 'i', 'o', 'u']
    if (!value) {
      return
    }

    var last = value.toLowerCase().substr(value.length - 1)
    var secondLast = value.toLowerCase().substr(value.length - 2, 1)
    var thirdLast = value.toLowerCase().substr(value.length - 3, 1)
    var lastTwo = value.toLowerCase().substr(value.length - 2)
    var lastThree = value.toLowerCase().substr(value.length - 3)
    var inArray = function (ar, value) {
      return ar.indexOf(value) !== -1
    }

    // participle or already past tense, don't want
    if (lastThree === 'ing' || lastTwo === 'ed') {
      return value
    }

    // Ends in 'e', simply add the 'd'
    if (last === 'e') {
      return value + 'd'
    }

    // Ends in 'c', add the 'ked'
    if (last === 'c') {
      return value + 'ked'
    }

    // Ends with consonant, vowel, consonant. I'm simple, double consonant and add 'ed'
    if (!inArray(vowels, thirdLast) && inArray(vowels, secondLast) && !inArray(vowels, last)) {
      return value + last + 'ed'
    }

    return value + 'ed'
  },

  /**
   * Vue filter to convert a slug to a more human friendly form.
   *
   * @param {String} value The value string.
   */
  'humanable': function (value) {
    var words = value.split(/[-_]+/g)
    var results = []
    for (var i = 0; i < words.length; i++) {
      var letter = words[i].charAt(0).toUpperCase()
      results.push(letter + words[i].slice(1))
    }
    return results.join(' ')
  },

  /**
   * Vue filter to convert the given value to percent.
   * http://jsfiddle.net/bryan_k/qauf3qyh/
   *
   * @param {String} value    The value string.
   * @param {Number} decimals The number of decimal places.
   */
  'percentage': function (value, decimals) {
    if (!value) {
      value = 0
    }

    if (!decimals) {
      decimals = 0
    }

    value = value * 100
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    value = value + '%'
    return value
  },

  /**
   * Vue filter to round the decimal to the given place.
   * http://jsfiddle.net/bryan_k/3ova17y9/
   *
   * @param {String} value    The value string.
   * @param {Number} decimals The number of decimal places.
   */
  'round': function (value, decimals) {
    if (!value) {
      value = 0
    }

    if (!decimals) {
      decimals = 0
    }

    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
    return value
  },

  /**
   * Vue filter to print the given value as currency.
   *
   * @param {String} value The value string.
   */
  'money': function (value) {
    if (!value) {
      return "0"
    }
    var formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0, 
      minimumFractionDigits: 0,
      style: 'decimal'
    })
    return formatter.format(value)
  },

  /**
   * Vue filter to convert the given value to whole dollars, no change.
   * http://jsfiddle.net/bryan_k/2t6bqqfc/
   *
   * @param {String} value The value string.
   */
  'no-change': function (value, symbol) {
    if (!value) {
      value = 0
    }

    if (!symbol) {
      symbol = '$'
    }

    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.')[0]
    return symbol + value
  },

  /**
   * Vue filter to make a simple timestamp for an ISO date.
   * http://jsfiddle.net/bryan_k/44kqtpeg/
   *
   * @param {String} value The value string.
   */
  'timestamp': function (value) {
    var parts = value.split(' ')
    var date = parts[0]
    var time = parts[1]
    var hour = ''

    date = date.split('-')
    time = time.split(':')

    if (parseInt(time[0], 10) > 12) {
      hour = parseInt(time[0], 10) % 12
    } else {
      hour = parseInt(time[0], 10)
    }
    hour = hour < 10 ? '0' + hour : hour

    return '[' + date[1] + '/' + date[2] + ' ' + hour + ':' + time[1] + ']'
  },

  /**
   * Vue filter to truncate a string to the specified length.
   * @param {String} value The value string.
   */
  'truncate': function (value, length) {
    if (value.length < length) {
      return value
    }

    length = length - 3

    return value.substring(0, length) + '...'
  },

  /**
   * Vue filter for default value
   * @param {String} value the value string
   * @param {String} fallback the default value to render if value is empty/null
   *
   */
  'defvalue': function (value, fallback) {
    if (!value) return fallback
    else return value
  },

  /**
   * Format date as time ago
   */
  'daterelative': function (value) {
    return formatDistance(subDays(new Date(value), 0), new Date())
  },

  /**
   * Format date as standard date
   */
  'dateformat': function (value) {
    return format(new Date(value), 'do MMMM, yyy')
  },

  /**
   * Format date as just year or 'now' if null
   */
  'yearformat': function (value) {
    if (value) {
      return format(new Date(value), 'yyyy')
    } else {
      return 'now'
    }
  },

  /**
   * Format date as standard date
   */
  'datetimeformat': function (value) {
    return format(new Date(value), 'H:mm, do MMMM, yyy')
  },

  /**
   * Format date as standard date
   */
  'datelongformat': function (value) {
    return format(new Date(value), 'PPPPp')
  },

  /**
   * Pluralize anything
   */
  'pluralize': function (word, value) {
    return pluralize(word, value)
  },

  /**
   * Unslugify anything
   */
  'unslugify': function (value) {
    return unslugify(value.replaceAll('_', ' '))
  }

}

export default filters
