<template>
  <div>
    <div v-if="startup">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">

              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goView">
                  <i class="uil uil-eye me-1"></i>
                  <span>View</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
                <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence">
                  <i class="uil uil-bag-slash me-1"></i>
                  <span>Manage Ringfence</span>
                </a>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>
              
              <h3>{{ startup.name }}</h3>

              <p class="mb-2 pre">
                {{ startup.description }}
              </p>
            </div>
          </div>
        </div> <!-- end col -->
      </div>

      <div class="row">
        <div class="col pe-0">
          <div class="card">
            <div class="card-body">
              <h4>Current Ringfence</h4>

              <div v-if="ringfenceLoaded">
                <p v-if="!ringfence.enabled">
                  No Ringfence has been set up yet.
                </p>
                <div v-else>
                  <p>
                    Excluded Contacts: {{ ringfence.count }}<br>
                  </p>
                  <button class="btn btn-outline-primary btn-sm" @click.prevent="toggleContacts">
                    <i class="uil uil-user me-1"></i>
                    <span v-if="showContacts">Hide Excluded Contacts</span>
                    <span v-else>View Excluded Contacts</span>
                  </button>

                  <table class="table table-hover mt-4" v-if="showContacts">
                    <thead>
                      <tr>
                        <th class="orderable">
                          <a href="?sort=full_name">Full name</a>
                        </th>
                        <th>
                          Title
                        </th>
                        <th class="orderable">
                          <a href="?sort=full_name">Company</a>
                        </th>
                        <th class="orderable">
                          <a href="?sort=full_name">Email</a>
                        </th>
                        <th class="orderable">
                          <a href="?sort=full_name">Email status</a>
                        </th>
                        <th class="orderable">
                          <a href="?sort=full_name">Phone number</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="contact in ringfence.contacts" :key="contact.id">
                        <td>       
                          <router-link :to="`/contacts/${contact.id}/`">{{ contact.first_name }} {{ contact.last_name }}</router-link>
                          <span class="mx-2">
                            <i class="uil uil-user" v-if="contact.met_in_person"></i>
                            <i class="uil uil-phone" v-if="contact.over_call"></i>
                            <i class="uil uil-envelope" v-if="contact.over_email"></i>
                          </span>
                        </td>
                        <td> 
                         <template v-if="contact.title && contact.title.length">
                            <span v-for="(item, index) in contact.title" :key="index">
                                <span v-if="item" class="me-2">{{ item.name }}</span>
                                <span v-if="index < contact.title.length-1">, </span>
                            </span>
                          </template>
                          <template v-else>
                            —
                          </template>
                        </td>
                        <td>
                          {{ contact.company.name || '—' }}
                        </td>
                        <td>
                          {{ contact.email || '—' }}
                        </td>
                         <td>
                          {{ contact.email_status || '—' }}
                        </td>
                        <td>
                          {{ contact.phone_number || '—' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <loader size="32px"></loader>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>Update Ringfence data</h4>

              <div class="pre mb-2">
                Please upload a CSV with the following fields:
                <code>
                  name, website, domain
                </code>
                <br>
                Where <code>name</code>: the name of the company, <code>website</code>: the website of the company, <code>domain</code>: the email domain of the contact.
                <br>
                Note: the website does not have to be cleaned and can contain "http://" or "https://" etc.
              </div>
              <div class="pre mb-2">
                You can use this file as a template:
                <a href="/static/ringfence_template.csv" download>ringfence_template.csv</a>
              </div>
              
              <div class="form-group mb-3">
                <input
                  ref="file"
                  type="file"
                  class="form-control"
                  required
                  accept=".csv"
                  @change="onFileChange"
                >
              </div>

              <div class="pre mb-2">
                Note: the current Ringfence data will be overwritten.
              </div>

              <button class="btn btn-outline-primary me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="goView">
                Cancel
              </button>
              <button class="btn btn-primary me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Upload Ringfence
              </button>
            </div>
          </div>
        </div> <!-- end col -->

      </div>
      <!-- end row -->
    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'startup-edit',

  data() {
    return {
      file: null,
      startup: null,
      ringfence: null,
      ringfenceLoaded: false,
      showContacts: false,
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsCountries: state => state.investors.countries
    })
  },

  mounted() {
    window.scrollTo(0, 0)
    this.load()
  },

  methods: {
    reload() {
      this.file = null
      this.startup = null
      this.ringfence = null
      this.ringfenceLoaded = false
      this.showContacts = false
      this.isSubmitting = false
      this.$refs.file.value = ''
      this.$nextTick(() => {
        this.load()
      })
    },
    load() {
      this.$store.dispatch('LOAD_STARTUP', this.$route.params.id)
        .then((response) => {
          this.startup = response.data
          this.$store.dispatch('LOAD_RINGFENCE', this.startup.id)
            .then((response) => {
              this.ringfence = response.data
              this.ringfenceLoaded = true
            })
        })
    },
    goView() {
      this.$router.push(`/startups/${this.startup.id}`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.startup.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/companies?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.startup.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    toggleContacts() {
      this.showContacts = !this.showContacts
    },
    submit() {
      if (!this.file) { return }
      this.isSubmitting = true
      const payload = {
        startup_id: this.startup.id,
        file: this.file,
        upload: true
      }
      this.$store.dispatch('UPLOAD_RINGFENCE', payload)
        .finally(() => {
          this.isSubmitting = false
          this.$nextTick(() => {
            this.reload()
          })
        })
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.file = files[0]
    }
  }
}
</script>
