<template>
  <div>
    <app-header></app-header>
    <div class="row mt-4">
      <div class="loader-overlay" v-if="isLoading">
        <loader></loader>
      </div>

      <div v-else class="col-12 col-md-8 offset-md-2">  
        <div class="row">
            <div class="col-12">
              <h1 class="page-title text-center">Select Company</h1>
              <p class="text-center mb-4">Choose the company you want to work with:</p>

              <div class="row" v-if="startups">
                <div
                  v-for="startup in startups.results" key="startup.id"
                  class="col-lg-4 col-md-6 mb-2 cursor-pointer hover-opacity"
                >
                  <div class="card d-flex h-100 mb-0" @click.prevent="selectStartup(startup)">
                    <div class="card-body d-flex justify-content-between flex-column">
                      <div>
                        <h3>{{ startup.name }}</h3>
                        <p>
                          {{ $filters.truncate(startup.description || '—', 80) }}
                        </p>
                      </div>
                      <div>
                        <a href="#" class="btn btn-outline-primary float-end">Select</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <pagination
                :items="startups"
                :currentPage="page"
                :perPage="perPage"
                @pagechanged="onPageChanged"
              >
              </pagination> -->

              <div class="d-flex justify-content-between">
                <router-link :to="{ name: 'startup_profile_add' }" class="btn btn-outline-primary my-4">Create New Company</router-link>
              </div>
          
          </div>
        </div>
      </div>
      
      <hr class="space space-md" />
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'startups-selector',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      page: 1,
      perPage: 1000,
      isLoading: false
    }
  },

  computed: {
    ...mapState({
      startups: state => state.startups.startups
    })
  },

  mounted() {
    this.$store.dispatch('LOAD_STARTUPS', { limit: this.perPage })
  },

  methods: {
    onPageChanged(pageNumber) {
      const offset = (pageNumber - 1) * this.perPage
      this.$store.dispatch('LOAD_STARTUPS', { offset, limit: this.perPage })
        .then(() => {
          this.page = pageNumber
        })
    },
    selectStartup(startup) {
      this.isLoading = true

      this.$store.commit('SET_CURRENT_STARTUP', startup)
      this.$store.commit('SET_SEARCH_FORM', [])
      this.$store.commit('SET_CURRENT_SEARCH', null)
      this.$store.commit('SET_COMPANIES', null)
      this.$store.commit('SET_CONTACTS', null)
      this.$store.commit('RESET_BREAKDOWN_OPTIONS')
      this.$store.commit('SET_ASTEL_CONTACTS', [])
      this.$store.commit('SET_ASTEL_COMPANIES', [])
      this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: startup.id }).then(() => {
        window.location.href = '/investors/companies/?match=true'
      })
    }
  }
}
</script>
