<template>
  <div>
    <!-- fields:
    Company Information	Team	Employee headcount and Geographies	Product & Technology	"Traction & Growth Metrics
    (Explanation: This is a section where we track prior traction and achievements. If these numbers/points are not strong, leave them blank)"	Financial Information	Previous funding information	Current funding round	Required Documents
    Company Name		Number of Full time Employees - What is the total number of full time employees in your company?	Technology developed in-house - Is you technology developed in-house? 	Letters of Intent Secured - How many Letters of Intent have your secured?	Generating Revenue? - Is the company generating revenue? Y/N	Capital raised so far - What is the total amount of capital raised so far?	Total Target Raise - How much is total target raise for this round?	Pitch Deck Upload - Please upload your latest Pitch Deck in PDF & Powerpoint version here, or in our shared Google Drive.
    Registration Country - In which country is your company registered?	Contact Person Name	Employee growth rate (YoY%) - What is your YoY employee growth rate?	MVP readiness - Has the Minimum Viable Product been built?	Retention Rate - What is your customer Retention Rate? (Please include it if this is a high number)	Current Monthly Recurring Revenue - What is the company's current monthly recurring revenue?	Previous round's valuation - What is the previous round's valuation?	Amount Raised in this round until today - What is the amount raised for this round until today?	Executive Summary Upload - Please upload your latest Executive Summary in PDF & Powerpoint version here, or in our shared Google Drive.
    Headquarters City - In which city is your company headquartered?	Contact Person Email	Team Locations - What are the countries, where your team members are located in?	Key selling points - What are the key selling points of your business?	Churn Rate - What is your customer Churn Rate? (Please include it if this is a low number)	Current Annual Recurring Revenue - What is the company's current annual recurring revenue?	Types of investors in previous rounds/ currently on cap table - What are the types of investors currently on your cap table, that invested in previous rounds?	Minimum ticket size - What is the minimum ticket size you are accepting for this round?	CapTable Upload - Please upload your CapTable, following the CSV format example below
    One liner Description - How would you describe your company in one line?	Contact Person Phone Number	C-Level Exits - Has any C-Level team member had prior exits? If yes, please elaborate.	Product's key features & functionalities - What are the Product's key features & functionalities?	Number of active users/clients - What is the total number of your active users/clients?	Projected Revenue for this year - What is the company's projected revenue for this year?	Notable institutional investors - What are the notable institutional investors on your cap table?	Average ticket size - What is the average ticket size you are looking to secure for this round? (from-to)	Ringfence Upload - Please upload your Ringfence list, following the CSV format example below
    Full description -  How would you describe your company in 1-2 paragraphs?	Contact Person City	C-Level Prior Common Projects - Has the C-Level team worked with each other before in other companies?	Competitive advantages - What are your product's competitive advantages?	Awards - What awards or distinctions have you won in your industry?	Revenue Growth Rate (YoY or CAGR) - What is the company's revenue growth rate (YoY or CAGR)?	Locations of current investors - What are the locations of your current investors?	Current pre-money valuation - What is your current pre-money valuation?	
    Sectors -  What are the industries & sectors keywords in which your company operates in. Please add as many as you can (eg. gaming, tech, music, real estate, healthcare)			Link to product's demo - Please provide us with a link to your product's demo, ideally a 2-3min video.	Timing until Break-even - What is your tiiming until the company breaks even? (Note to Alex: add breakdown similar to Runway Duration - from 3 months to 3 years+)	Currently profitable? - Is the company currently profitable? Y/N		Stage - What is your current stage of investment? (Dropdown selection of Pre-Seed, Seed, Series A.......)	
    Website - Company Website	Add new team member feature - to be integrated on the "edit" section of the startup profile			Lifetime Revenue - How much lifetime revenue has the company generated until today? (Please include it if this is a high number)	Gross profit - What is the company's gross profit?		Date of closing - What is the date you are targeting to clsoe this round?	
    Shared email used - What is the shared email address we are going to use?	First Name			Names of big institutional clients - What are the names of some of your big institutional clients?	Gross margin - What is the company's gross margin?		Preferred investor types - What investor types are you looking to secure for this round? (Dropdown selection of VC, HNWI, Family Office,..........)	
    Expansion Countries - In which countries are you looking to expand within the next 12-24 months?	Last Name			Pipeline of big institutional clients - What is the pipeline of some big institutional clients?	EBITDA - What is the company's EBITDA?		Preferred investor region/country - What are the preferred investor regions & countries you are looking to approach for this round? (Note to Alex: Here, under this question, we can have 2 fields for the founder to fill in, one with regions, one with countries. Otherwise, feel free to split it into 2 different questions)	
    Email			Product fully built - Has the product been fully built?	Monthly Burn Rate - What is the company's monthly burn rate?		Lead investor secured - Have you secured a lead investor? Y/N	
    Title			Top tier - experienced board members - What are some top-tier experienced board members and what is their career traction? Please add if the board members have important traction.	Current Runway - What is the company's current runway? (Note to Alex: add breakdown similar to Runway Duration - from 3 months to 3 years+)		Lead Investor's name & reputation - If the lead investor has been secured, what is their name and reputation (prior notable investments, AUM etc)	
    City Located			Key Strategic partnerships secured - What are some key strategic partnerships that the company has secured? Please add if you have secured important partnerships.	Different revenue-generating streams - What are the company's different revenue-generating streams?		Smart money - Are you looking for smart money? (Note to Alex: Same selection as before)	
    Linkedin - Please provide us with this team member's LinkedIn URL			Other Traction Points - What are some other traction points that are critical to be added/known?	Sales valuation multiple - What is your sales valuation multiple?		Use of proceeds - What is the use of proceeds from this round?	
    About (previous achievements & experience) - What are this team member's previous achievements and/or exits and what expertise do they bring to your company?				CAC - What is the company's Customer Acquisition Cost?		Exit Strategy - What is the company's strategy for an exit?	
    Events attending - What events is this team member attending in the next 6 months?				CLTV - What is the Customer's Lifetime Value?		Investment opportunity description - Please describe in 1 paragraph the investment opportunity	 -->
    <div v-if="instance">
      <div class="row">
        <div class="col-12">

          <div class="card">
            <div class="card-body">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <template v-if="startupId">
                  <a href="#" class="btn btn-outline-primary" @click.prevent="goView">
                    <i class="uil uil-eye me-1"></i>
                    <span>View</span>
                  </a>
                  <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete" v-if="user && user.is_staff">
                    <i class="uil uil-trash me-1"></i>
                    <span>Delete</span>
                  </a>
                  <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence" v-if="user && user.is_staff">
                    <i class="uil uil-bag-slash me-1"></i>
                    <span>Manage Ringfence</span>
                  </a>
                </template>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch" v-if="user && user.is_staff">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>

              <h3 v-if="startupId">{{ instance.name }}</h3>
              <h3 v-else>Create Profile</h3>

              <div class="form-group mb-3">
                <label for="id_name">Company Name:</label>
                <input id="id_name" type="text" name="name" class="form-control" required v-model="instance.name">
                
                <p v-if="errors.name && errors.name.length">
                  <span v-for="error in errors.name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_registered_country">In which country is your company registered?</label>

                <v-select
                  name="registered_country"
                  v-model="instance.registered_country"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>
                
                <p v-if="errors.registered_country && errors.registered_country.length">
                  <span v-for="error in errors.registered_country" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-2">
                <span class="fw-bold me-2">In which city is your company headquartered?</span>
                <v-select
                  name="registered_country"
                  v-model="instance.company_location_city"
                  @search="searchCities"
                  :options="optionsCities"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.company_location_city && errors.company_location_city.length">
                  <span v-for="error in errors.company_location_city" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_common_one_liner">How would you describe your company in one line?</label>
                <textarea id="id_description" type="text" name="common_one_liner" class="form-control" required rows="1"
                  v-model="instance.common_one_liner">
                </textarea>

                <p v-if="errors.common_one_liner && errors.common_one_liner.length">
                  <span v-for="error in errors.common_one_liner" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_description">How would you describe your company in 1-2 paragraphs?</label>
                <textarea id="id_description" type="text" name="description" class="form-control" required rows="5"
                  v-model="instance.description">
                </textarea>

                <p v-if="errors.description && errors.description.length">
                  <span v-for="error in errors.description" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_industry_sector_tags">What are the industries & sectors keywords in which your company operates in. Please add as many as you can (eg. gaming, tech, music, real estate, healthcare)</label>

                <v-select
                  name="industry_sector_tags"
                  v-model="instance.industry_sector_tags"
                  :options="tagOptions"
                  @search="searchTags"
                  multiple
                  taggable
                  :close-on-select="false"
                  :clear-search-on-select="true"
                >
                </v-select>

                <p v-if="errors.industry_sector_tags && errors.industry_sector_tags.length">
                  <span v-for="error in errors.industry_sector_tags" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_name">Company Website:</label>
                <input id="id_website" type="text" name="website" class="form-control" required v-model="instance.website">

                <p v-if="errors.website && errors.website.length">
                  <span v-for="error in errors.website" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_astel_shared_email">What is the shared email address we are going to use?</label>
                <input id="id_astel_shared_email" type="email" name="astel_shared_email" class="form-control" v-model="instance.astel_shared_email">
                
                <p v-if="errors.astel_shared_email && errors.astel_shared_email.length">
                  <span v-for="error in errors.astel_shared_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_expansion_countries">In which countries are you looking to expand within the next 12-24 months?</label>

                <v-select name="expansion_countries" v-model="instance.expansion_countries" :options="optionsCountries"
                  :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.expansion_countries && errors.expansion_countries.length">
                  <span v-for="error in errors.expansion_countries" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Team
              </h4>
              
              <div class="form-group mb-3">
                <label for="id_first_name">Contact Person First Name:</label>
                <input id="id_first_name" type="text" name="first_name" class="form-control" v-model="instance.first_name">
                
                <p v-if="errors.first_name && errors.first_name.length">
                  <span v-for="error in errors.first_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-3">
                <label for="id_last_name">Contact Person Last Name:</label>
                <input id="id_last_name" type="text" name="last_name" class="form-control" v-model="instance.last_name">
                
                <p v-if="errors.last_name && errors.last_name.length">
                  <span v-for="error in errors.last_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_email_address">Contact Person Email:</label>
                <input id="id_email_address" type="email" name="email_address" class="form-control" v-model="instance.email_address">
                
                <p v-if="errors.email_address && errors.email_address.length">
                  <span v-for="error in errors.email_address" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_phone">Contact Person Phone:</label>
                <input id="id_phone" type="text" name="last_name" class="form-control" v-model="instance.phone">
                
                <p v-if="errors.phone && errors.phone.length">
                  <span v-for="error in errors.phone" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <!-- <div class="form-group mb-3">
                <label for="id_cofounder_email">Cofounder (n) Email:</label>
                <input id="id_cofounder_email" type="email" name="cofounder_email" class="form-control" v-model="instance.cofounder_email">
                
                <p v-if="errors.cofounder_email && errors.cofounder_email.length">
                  <span v-for="error in errors.cofounder_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_founders_bio">Bio:</label>
                <textarea id="id_founders_bio" name="founders_bio" class="form-control"
                  required rows="5" v-model="instance.founders_bio">
                </textarea>
                
                <p v-if="errors.founders_bio && errors.founders_bio.length">
                  <span v-for="error in errors.founders_bio" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> -->

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Employee headcount and Geographies
              </h4>

              <div class="form-group mb-3">
                <label for="id_employee_number">What is the total number of full time employees in your company?</label>
                <v-number-input
                  v-model="instance.employee_number"
                  v-bind="numberInputConfig"
                />
                
                <p v-if="errors.employee_number && errors.employee_number.length">
                  <span v-for="error in errors.employee_number" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_total_employee_growth_rate">What is your YoY employee growth rate? (%)</label>
                <v-number-input
                  v-model="instance.total_employee_growth_rate"
                  v-bind="numberInputConfig"
                />
                
                <p v-if="errors.total_employee_growth_rate && errors.total_employee_growth_rate.length">
                  <span v-for="error in errors.total_employee_growth_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_team_location">What are the countries, where your team members are located in?</label>

                <v-select
                  name="team_location"
                  v-model="instance.team_location"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.team_location && errors.team_location.length">
                  <span v-for="error in errors.team_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_c_level_exits">Has any C-Level team member had prior exits? If yes, please elaborate.</label>
                <textarea id="id_c_level_exits" type="text" name="c_level_exits" class="form-control" required rows="3"
                  v-model="instance.c_level_exits">
                </textarea>

                <p v-if="errors.c_level_exits && errors.c_level_exits.length">
                  <span v-for="error in errors.c_level_exits" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_c_level_work">Has the C-Level team worked with each other before in other companies?</label>
                <textarea id="id_c_level_work" type="text" name="c_level_exits" class="form-control" required rows="3"
                  v-model="instance.c_level_work">
                </textarea>

                <p v-if="errors.c_level_work && errors.c_level_work.length">
                  <span v-for="error in errors.c_level_work" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Product and Technology
              </h4>

              <div class="form-group mb-2">
                <label for="id_in_house_technology">Is you technology developed in-house?</label>

                <v-select
                  name="in_house_technology"
                  v-model="instance.in_house_technology"
                  :options="YesNoOtherOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.in_house_technology && errors.in_house_technology.length">
                  <span v-for="error in errors.in_house_technology" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3" v-if="instance.in_house_technology === 2">
                <label for="id_in_house_technology_other">Please explain, what do you mean by other?</label>
                <textarea id="id_in_house_technology_other" name="in_house_technology_other" class="form-control"
                          required rows="5" v-model="instance.in_house_technology_other">
                </textarea>

                <p v-if="errors.in_house_technology_other && errors.in_house_technology_other.length">
                  <span v-for="error in errors.in_house_technology_other" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_mvp_ready">Has the Minimum Viable Product been built?</label>

                <v-select
                  name="id_mvp_ready"
                  v-model="instance.mvp_ready"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.mvp_ready && errors.mvp_ready.length">
                  <span v-for="error in errors.mvp_ready" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_key_selling_points">What are the key selling points of your business?</label>
                <textarea id="id_key_selling_points" name="key_selling_points" class="form-control"
                  required rows="5" v-model="instance.key_selling_points">
                </textarea>
                
                <p v-if="errors.key_selling_points && errors.key_selling_points.length">
                  <span v-for="error in errors.key_selling_points" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_key_features">What are the Product's key features & functionalities?</label>
                <textarea id="id_key_features" name="key_features" class="form-control"
                  required rows="5" v-model="instance.key_features">
                </textarea>
                
                <p v-if="errors.key_features && errors.key_features.length">
                  <span v-for="error in errors.key_features" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_competitive_advantages">What are your product's competitive advantages?</label>
                <textarea id="id_competitive_advantages" name="competitive_advantages" class="form-control"
                  required rows="5" v-model="instance.competitive_advantages">
                </textarea>
                
                <p v-if="errors.competitive_advantages && errors.competitive_advantages.length">
                  <span v-for="error in errors.competitive_advantages" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_demo">Please provide us with a link to your product's demo, ideally a 2-3min video.</label>
                <input id="id_demo" type="text" name="demo" class="form-control" required v-model="instance.demo">

                <p v-if="errors.demo && errors.demo.length">
                  <span v-for="error in errors.demo" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Traction and Growth Metrics
              </h4>
              <p>
                This is a section where we track prior traction and achievements. If these numbers/points are not strong, leave them blank)
              </p>

              <div class="form-group mb-3">
                <label for="id_letters_of_intent_number">How many Letters of Intent have your secured?</label>
                <v-number-input
                  v-model="instance.letters_of_intent_number"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.letters_of_intent_number && errors.letters_of_intent_number.length">
                  <span v-for="error in errors.letters_of_intent_number" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_retention_rate">What is your customer Retention Rate? (Please include it if this is a high number)</label>
                <v-number-input
                  v-model="instance.retention_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.retention_rate && errors.retention_rate.length">
                  <span v-for="error in errors.retention_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_churn_rate">What is your customer Churn Rate? (Please include it if this is a low number)</label>
                <v-number-input
                  v-model="instance.churn_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.churn_rate && errors.churn_rate.length">
                  <span v-for="error in errors.churn_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_active_users">What is the total number of your active users/clients?</label>
                <v-number-input
                  v-model="instance.active_users"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.active_users && errors.active_users.length">
                  <span v-for="error in errors.active_users" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_awards">What awards or distinctions have you won in your industry?</label>
                <input
                  id="id_awards"
                  type="text"
                  name="awards"
                  class="form-control"
                  v-model="instance.awards"
                >
                
                <p v-if="errors.awards && errors.awards.length">
                  <span v-for="error in errors.awards" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_time_to_breakeven">What is your tiiming until the company breaks even?</label>

                <v-select name="time_to_breakeven" v-model="instance.time_to_breakeven"
                  :options="optionsRunwayDuration" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.time_to_breakeven && errors.time_to_breakeven.length">
                  <span v-for="error in errors.time_to_breakeven" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_lifetime_revenue">How much lifetime revenue has the company generated until today? (Please include it if this is a high number)</label>
                <v-number-input
                  v-model="instance.lifetime_revenue"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.lifetime_revenue && errors.lifetime_revenue.length">
                  <span v-for="error in errors.lifetime_revenue" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_big_clients">What are the names of some of your big institutional clients?</label>
                <input
                  id="id_big_clients"
                  type="text"
                  name="big_clients"
                  class="form-control"
                  v-model="instance.big_clients"
                >
                
                <p v-if="errors.big_clients && errors.big_clients.length">
                  <span v-for="error in errors.big_clients" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_pipeline_big_clients">What is the pipeline of some big institutional clients?</label>
                <input
                  id="id_pipeline_big_clients"
                  type="text"
                  name="pipeline_big_clients"
                  class="form-control"
                  v-model="instance.pipeline_big_clients"
                >
                
                <p v-if="errors.pipeline_big_clients && errors.pipeline_big_clients.length">
                  <span v-for="error in errors.pipeline_big_clients" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_product_ready">Has the product been fully built?</label>

                <v-select
                  name="id_product_ready"
                  v-model="instance.product_ready"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.product_ready && errors.product_ready.length">
                  <span v-for="error in errors.product_ready" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_board_members_traction">What are some top-tier experienced board members and what is their career traction? Please add if the board members have important traction.</label>
                <textarea id="id_board_members_traction" name="board_members_traction" class="form-control"
                  required rows="5" v-model="instance.board_members_traction">
                </textarea>
                
                <p v-if="errors.board_members_traction && errors.board_members_traction.length">
                  <span v-for="error in errors.board_members_traction" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_other_traction_points">What are some other traction points that are critical to be added/known?</label>
                <textarea id="id_other_traction_points" name="other_traction_points" class="form-control"
                  required rows="5" v-model="instance.other_traction_points">
                </textarea>
                
                <p v-if="errors.other_traction_points && errors.other_traction_points.length">
                  <span v-for="error in errors.other_traction_points" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
<!-- 
              <div class="form-group mb-3">
                <label for="id_annual_growth_rate">Annual Growth Rate:</label>
                <v-number-input
                  v-model="instance.annual_growth_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.annual_growth_rate && errors.annual_growth_rate.length">
                  <span v-for="error in errors.annual_growth_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
             -->

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Financial Information
              </h4>

              <div class="form-group mb-2">
                <label for="id_revenue_generating">Is the company generating revenue?</label>

                <v-select
                  name="revenue_generating"
                  v-model="instance.revenue_generating"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.revenue_generating && errors.revenue_generating.length">
                  <span v-for="error in errors.revenue_generating" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_MRR">What is the company's current monthly recurring revenue?</label>
                <v-number-input
                  v-model="instance.current_MRR"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_MRR && errors.current_MRR.length">
                  <span v-for="error in errors.current_MRR" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_ARR">What is the company's current annual recurring revenue?</label>
                <v-number-input
                  v-model="instance.current_ARR"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_ARR && errors.current_ARR.length">
                  <span v-for="error in errors.current_ARR" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_projected_revenue">What is the company's projected revenue for this year?</label>
                <v-number-input
                  v-model="instance.projected_revenue"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.projected_revenue && errors.projected_revenue.length">
                  <span v-for="error in errors.projected_revenue" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_revenue_growth">What is the company's revenue growth rate (YoY or CAGR)?</label>
                <v-number-input
                  v-model="instance.revenue_growth"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.revenue_growth && errors.revenue_growth.length">
                  <span v-for="error in errors.revenue_growth" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_profitable">Is the company currently profitable?</label>

                <v-select
                  name="profitable"
                  v-model="instance.profitable"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.profitable && errors.profitable.length">
                  <span v-for="error in errors.profitable" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_gross_profit">What is the company's gross profit? (US$)</label>
                <v-number-input
                  v-model="instance.gross_profit"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.gross_profit && errors.gross_profit.length">
                  <span v-for="error in errors.gross_profit" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_gross_margin">What is the company's gross margin?</label>
                <v-number-input
                  v-model="instance.gross_margin"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.gross_margin && errors.gross_margin.length">
                  <span v-for="error in errors.gross_margin" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_ebitda">What is the company's EBITDA?</label>
                <v-number-input
                  v-model="instance.ebitda"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.ebitda && errors.ebitda.length">
                  <span v-for="error in errors.ebitda" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_monthly_burn_rate">What is the company's monthly burn rate?</label>
                <v-number-input
                  v-model="instance.monthly_burn_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.monthly_burn_rate && errors.monthly_burn_rate.length">
                  <span v-for="error in errors.monthly_burn_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_runway_duration">What is the company's current runway?</label>

                <v-select name="runway_duration" v-model="instance.runway_duration"
                  :options="optionsRunwayDuration" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.runway_duration && errors.runway_duration.length">
                  <span v-for="error in errors.runway_duration" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_revenue_generating_streams">What are the company's different revenue-generating streams?</label>
                <textarea 
                  id="id_revenue_generating_streams"
                  name="revenue_generating_streams"
                  class="form-control"
                  rows="5"
                  v-model="instance.revenue_generating_streams"
                >
                </textarea>
                
                <p v-if="errors.revenue_generating_streams && errors.revenue_generating_streams.length">
                  <span v-for="error in errors.revenue_generating_streams" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_sales_valuation_multiple">What is your sales valuation multiple?</label>
                <v-number-input
                  v-model="instance.sales_valuation_multiple"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.sales_valuation_multiple && errors.sales_valuation_multiple.length">
                  <span v-for="error in errors.sales_valuation_multiple" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_customer_acquisition_cost">What is the company's Customer Acquisition Cost? (US$)</label>
                <v-number-input
                  v-model="instance.customer_acquisition_cost"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.customer_acquisition_cost && errors.customer_acquisition_cost.length">
                  <span v-for="error in errors.customer_acquisition_cost" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_customer_lifetime_value">What is the Customer's Lifetime Value? (US$)</label>
                <v-number-input
                  v-model="instance.customer_lifetime_value"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.customer_lifetime_value && errors.customer_lifetime_value.length">
                  <span v-for="error in errors.customer_lifetime_value" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_LTV_CAC">LTV/CAC - What is the company's LTV/CAC ratio?</label>
                <v-number-input
                  v-model="instance.LTV_CAC"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.LTV_CAC && errors.LTV_CAC.length">
                  <span v-for="error in errors.LTV_CAC" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Previous Funding
              </h4>
              
              <div class="form-group mb-3">
                <label for="id_target_raise">What is the total amount of capital raised so far? (US$)</label>
                <v-number-input
                  v-model="instance.raised_so_far"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.raised_so_far && errors.raised_so_far.length">
                  <span v-for="error in errors.raised_so_far" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_last_funding_valuation">What is the previous round's valuation?</label>
                <v-number-input
                  v-model="instance.last_funding_valuation"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.last_funding_valuation && errors.last_funding_valuation.length">
                  <span v-for="error in errors.last_funding_valuation" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investor_type">What are the types of investors currently on your cap table, that invested in previous rounds?</label>

                <v-select
                  name="current_investor_type"
                  v-model="instance.current_investor_type"
                  :options="optionsInvestorTypes"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.current_investor_type && errors.current_investor_type.length">
                  <span v-for="error in errors.current_investor_type" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_notable_institutional_investors">What are the notable institutional investors on your cap table?</label>
                
                <v-select
                  name="notable_institutional_investors"
                  v-model="instance.notable_institutional_investors"
                  :options="companyNames"
                  @search="searchCompanyNames"
                  multiple
                  taggable
                  :close-on-select="false"
                >
                </v-select>

                <p v-if="errors.notable_institutional_investors && errors.notable_institutional_investors.length">
                  <span v-for="error in errors.notable_institutional_investors" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <!-- <div class="row align-items-center">
                  <div class="col-auto sectors-scrollable-body">
                    <span v-for="label in instance.notable_institutional_investors" :key="label" class="badge text-white text-bg-dark p-1 m-1">{{ label }}</span>
                  </div>
                </div> -->

              <!-- <div class="form-group mb-3">
                <label for="id_notable_institutional_investors">What are the notable institutional investors on your cap table?</label>
                <textarea id="id_notable_institutional_investors" type="text" name="notable_institutional_investors" class="form-control"
                  required rows="5" v-model="instance.notable_institutional_investors">
                </textarea>
                
                <p v-if="errors.notable_institutional_investors && errors.notable_institutional_investors.length">
                  <span v-for="error in errors.notable_institutional_investors" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> -->

              <div class="form-group mb-2">
                <label for="id_current_investors_location">What are the locations of your current investors?</label>

                <v-select
                  name="current_investors_location"
                  v-model="instance.current_investors_location"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.current_investors_location && errors.current_investors_location.length">
                  <span v-for="error in errors.current_investors_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <!-- <div class="form-group mb-2"><span class="fw-bold me-2">Current Investors:</span>
                <template v-if="instance.current_investors && instance.current_investors.length">
                  <span v-for="item in instance.current_investors" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>

                <p v-if="errors.current_investors && errors.current_investors.length">
                  <span v-for="error in errors.current_investors" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_lead_investor">Do you have Current Lead Investor on your current round?</label>

                <v-select
                  name="current_lead_investor"
                  v-model="instance.current_lead_investor"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.current_lead_investor && errors.current_lead_investor.length">
                  <span v-for="error in errors.current_lead_investor" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_total_funding_to_date">Total Funding to date:</label>
                <v-number-input
                  v-model="instance.total_funding_to_date"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.total_funding_to_date && errors.total_funding_to_date.length">
                  <span v-for="error in errors.total_funding_to_date" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> -->

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Current funding round
              </h4>

              <div class="form-group mb-3">
                <label for="id_target_raise">How much is total target raise for this round?</label>
                <v-number-input
                  v-model="instance.target_raise"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.target_raise && errors.target_raise.length">
                  <span v-for="error in errors.target_raise" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_round_raised">What is the amount raised for this round until today?</label>
                <v-number-input
                  v-model="instance.current_round_raised"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_round_raised && errors.current_round_raised.length">
                  <span v-for="error in errors.current_round_raised" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_minimum_ticket_size">What is the minimum ticket size you are accepting for this round?</label>
                <v-number-input
                  v-model="instance.minimum_ticket_size"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.minimum_ticket_size && errors.minimum_ticket_size.length">
                  <span v-for="error in errors.minimum_ticket_size" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <p class="text-strong">What is average ticket size you are looking for on this round?</p>
                <div class="d-flex">
                  <div>
                    <label for="id_ticket_size_from">From:</label>
                    <v-number-input
                      v-model="instance.ticket_size_from"
                      v-bind="numberInputConfig"
                    />
                    
                    <p v-if="errors.ticket_size_from && errors.ticket_size_from.length">
                      <span v-for="error in errors.ticket_size_from" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                  <div>
                    <label for="id_ticket_size_to">To:</label>
                    <v-number-input
                      v-model="instance.ticket_size_to"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.ticket_size_to && errors.ticket_size_to.length">
                      <span v-for="error in errors.ticket_size_to" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_valuation">Current Valuation:</label>
                <v-number-input
                  v-model="instance.current_valuation"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_valuation && errors.current_valuation.length">
                  <span v-for="error in errors.current_valuation" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investment_stage">Current Investment Stage:</label>

                <v-select name="current_investment_stage" v-model="instance.current_investment_stage"
                  :options="optionsInvestmentStages" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.current_investment_stage && errors.current_investment_stage.length">
                  <span v-for="error in errors.current_investment_stage" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_funding_close_date">Funding Close Date:</label>
                <input
                  id="id_funding_close_date"
                  type="date"
                  name="funding_close_date"
                  class="form-control"
                  v-model="instance.funding_close_date"
                >

                <p v-if="errors.funding_close_date && errors.funding_close_date.length">
                  <span v-for="error in errors.funding_close_date" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-2">
                <label for="id_preferred_investor_type">Preferred Investor Type:</label>

                <v-select name="preferred_investor_type" v-model="instance.preferred_investor_type"
                  :options="optionsInvestorTypes" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_type && errors.preferred_investor_type.length">
                  <span v-for="error in errors.preferred_investor_type" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_preferred_investor_region">Preferred Investor Region:</label>

                <v-select name="preferred_investor_region" v-model="instance.preferred_investor_region"
                  :options="optionsRegions" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_region && errors.preferred_investor_region.length">
                  <span v-for="error in errors.preferred_investor_region" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_smart_money_tags">Are you looking for smart money:</label>

                <v-select name="smart_money_tags" v-model="instance.smart_money_tags"
                  :options="optionsSmartMoneyTags" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.smart_money_tags && errors.smart_money_tags.length">
                  <span v-for="error in errors.smart_money_tags" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_exit_strategy">Exit Strategy:</label>

                <v-select name="exit_strategy" v-model="instance.exit_strategy"
                  :options="optionsExitStrategy" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.exit_strategy && errors.exit_strategy.length">
                  <span v-for="error in errors.exit_strategy" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_preferred_investor_location">Preferred Investor location:</label>

                <v-select name="preferred_investor_location" v-model="instance.preferred_investor_location"
                  :options="optionsCountries" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_location && errors.preferred_investor_location.length">
                  <span v-for="error in errors.preferred_investor_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_description">Investment Opportunity:</label>
                <textarea id="id_investment_opportunity" type="text" name="investment_opportunity" class="form-control"
                  required rows="10" v-model="instance.investment_opportunity">
                </textarea>

                <p v-if="errors.investment_opportunity && errors.investment_opportunity.length">
                  <span v-for="error in errors.investment_opportunity" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_pre_money_valuation">Pre-Money Valuation:</label>
                <v-number-input
                  v-model="instance.pre_money_valuation"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.pre_money_valuation && errors.pre_money_valuation.length">
                  <span v-for="error in errors.pre_money_valuation" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_lead_investor_name">Lead Investor Name and Reputation (if available):</label>
                <input
                  id="id_lead_investor_name"
                  type="text"
                  name="lead_investor_name"
                  class="form-control"
                  v-model="instance.lead_investor_name"
                >
                
                <p v-if="errors.lead_investor_name && errors.lead_investor_name.length">
                  <span v-for="error in errors.lead_investor_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_use_of_proceed">Use of Proceeds:</label>
                <textarea
                  id="id_use_of_proceed"
                  type="text"
                  name="use_of_proceed"
                  class="form-control"
                  rows="4"
                  v-model="instance.use_of_proceed"
                >
                </textarea>

                <p v-if="errors.use_of_proceed && errors.use_of_proceed.length">
                  <span v-for="error in errors.use_of_proceed" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_cap_table">Current Cap Table (CSV):</label>
                <input
                  ref="current_cap_table"
                  type="file"
                  class="form-control"
                  accept=".csv"
                  @change="onCapTableFileChange"
                  required
                >

                <p v-if="errors.current_cap_table && errors.current_cap_table.length">
                  <span v-for="error in errors.current_cap_table" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Fundraising Strategy
              </h4>

              <div class="form-group mb-1">
                <label for="id_pitch_deck_url">Pitch Deck (link to Docsend / Google Drive / etc):</label>
                <input id="id_pitch_deck_url" type="text" name="pitch_deck_url" class="form-control" required v-model="instance.pitch_deck_url">

                <p v-if="errors.pitch_deck_url && errors.pitch_deck_url.length">
                  <span v-for="error in errors.pitch_deck_url" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_pitch_deck_file">Pitch Deck (upload PDF):</label>
                <input
                  ref="pitch_deck_file"
                  type="file"
                  class="form-control"
                  accept=".pdf"
                  @change="onPitchDeckFileChange"
                >

                <p v-if="errors.pitch_deck_file && errors.pitch_deck_file.length">
                  <span v-for="error in errors.pitch_deck_file" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <hr>

              <div class="form-group mb-3">
                <label for="id_executive_summary_url">Executive Summary (link to Docsend / Google Drive / etc):</label>
                <input id="id_executive_summary_url" type="text" name="executive_summary_url" class="form-control" required v-model="instance.executive_summary_url">

                <p v-if="errors.executive_summary_url && errors.executive_summary_url.length">
                  <span v-for="error in errors.executive_summary_url" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_executive_summary_file">Executive Summary (upload PDF):</label>
                <input
                  ref="executive_summary_file"
                  type="file"
                  class="form-control"
                  accept=".pdf"
                  @change="onExecutiveSummaryFileChange"
                >

                <p v-if="errors.executive_summary_file && errors.executive_summary_file.length">
                  <span v-for="error in errors.executive_summary_file" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Country One Liner
              </h4>
              

              <div class="form-group mb-2">
                <label for="selectedRegion">Select Region for Oneliner:</label>
                <v-select
                  id="selectedRegion"
                  v-model="selectedRegion"
                  :options="optionsRegions"
                  :reduce="x => x.id"
                  label="name"
                ></v-select>
              </div>
              

            <div v-if="selectedRegion">
              <div class="form-group mb-3">
                <label for="countryOneLiner">Country One Liner:</label>
                <textarea
                  id="countryOneLiner"
                  class="form-control"
                  rows="2"
                  v-model="countryOneLiner"
                ></textarea>
                <p v-if="errors.country_one_liner && errors.country_one_liner.length">
                  <span
                    v-for="error in errors.country_one_liner"
                    :key="error"
                    class="text-danger"
                  >{{ error }}</span>
                </p>
              </div>
              <button @click.prevent="addCountryOneLiner" class="btn btn-outline-secondary">
                Add Country One-Liner
              </button>
            </div>

            <div class="form-group mb-2">
              <label>List of Country One Liner:</label>
              <ul>
                <li v-for="(oneLiner, index) in instance.country_one_liner" :key="index">
                  <h5>{{getRegionName(oneLiner.region)}}</h5> <b>One liner</b>: &nbsp; {{ oneLiner.country_one_liner }}
                  <button @click.prevent="removeCountryOneLiner(index)" class="btn btn-link text-danger">
                    Remove
                  </button>
                </li>
              </ul>
            </div>
              
              </div>
          
        </div>
          
        </div>
        

      </div>
      <!-- end row -->

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center float-end">
              <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
                <button class="btn btn-primary" :class="{ disabled: isSubmitting | instance === {} }" @click.prevent="submit">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'startup-edit',

  data() {
    return {
      instance: null,
      instance: {
        country_one_liner: [],
      },
      selectedRegion: null,
      countryOneLiner: '',
      countryOneLiners: [],
      errors: {},
      isSubmitting: false,
      YesNoOtherOptions: [{id: 1, label: 'YES'}, {id: 0, label: 'NO'}, {id: 2, label: 'OTHER'}],
      yesNoOptions: [{id: 1, label: 'YES'}, {id: 0, label: 'NO'}],
      tagOptions: [],
      companyNames: [],
      numberInputConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
        allowBlank: true,
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      stateForm: state => state.startups.form,
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsoneLiner: state => state.investors.oneLiner,
      optionsCities: state => state.investors.cities,
      optionsCountries: state => state.investors.countries,
      optionsRegions: state => state.investors.regions,
      optionsSmartMoneyTags: state => state.startups.optionsSmartMoneyTags,
      optionsExitStrategy: state => state.startups.optionsExitStrategy,
      optionsRunwayDuration: state => state.startups.optionsRunwayDuration,
    }),
    startupId() {
      return this.$route.params.id || (this.current && this.current.id)
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
  },

  mounted() {
    window.scrollTo(0, 0)

    if (this.startupId) {
      Promise.all([
        this.$store.dispatch('SEARCH_COMPANY_NAMES').then((response) => {
          this.companyNames = response.data
        }),
        this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
        this.$store.dispatch('LOAD_STARTUP_FIELD_OPTIONS'),
        this.$store.dispatch('SEARCH_TAGS', "").then((response) => {
          this.tagOptions = response.data
        }),
        this.$store.dispatch('LOAD_STARTUP', this.startupId)
          .then((response) => {
            const res = response.data
            if (res.notable_institutional_investors) {
              res.notable_institutional_investors = res.notable_institutional_investors
                .split(',')
                .map(item => item.trim())
            } else {
              res.notable_institutional_investors = []
            }
            this.instance = res
          })
      ]).then(() => {
        this.setM2Ms()
        this.loadInvestmentStages()
        this.loadInvectmentSectores()
      })
    } else {
      this.instance = {}
      if (this.stateForm) {
        this.instance = this.stateForm
      }
      window.instance = this.instance
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    }
  },

  methods: {
    handleCreatedOption(newOption) {
      // Add the new option to the optionsInvestmentStages array
      this.new_investment_stage_keywords.push(newOption);
    },
    loadInvectmentSectores(){
      this.industry_sector_tags = this.instance.industry_sector_tags;
    },
    loadInvestmentStages(){
      // Convert the integers array to a Set for faster lookup
      const integerSet = new Set(this.instance.current_investment_stage);

      // Filter the objects array
      this.current_investment_stage = this.optionsInvestmentStages.filter(obj => {
        // Get the value of the specified key from the object
        const value = obj["id"];

        // Check if the value is in the set of integers
        return integerSet.has(value);
      });

      this.selected_current_investment_stage = this.current_investment_stage;
    },
    showModal() {
      this.modal.show()
      this.selected_industry_sector_tags = this.instance.industry_sector_tags;
    },
    hideModal() {
      this.modal.hide()
    },
    saveKeywords() {
      // Implement your logic to save the selected keywords
      console.log('Saving keywords...')
      this.instance.industry_sector_tags = this.selected_industry_sector_tags;
      this.hideModal()
    },
    findMatchingInvestingStages() {
      this.is_stages_loading = true;
      let selectedKeywords = [];
      this.new_investment_stage_keywords.forEach(keyword => {
        selectedKeywords.push(keyword.name);
      });
      this.selected_current_investment_stage.forEach(keyword => {
        selectedKeywords.push(keyword);
      });
      const uniqueSelectedKeywords = [...new Set(selectedKeywords)];
      const lowerSearchStrings = (uniqueSelectedKeywords || [])
        .filter(keyword => keyword != null)
        .map(keyword => {
          if (typeof keyword === 'string') {
            return keyword.toLowerCase();
          } else if (typeof keyword === 'object' && keyword !== null) {
            return keyword["name"].toLowerCase();
          }
          return keyword; // Return as-is if it's neither a string nor an object
        });
      const matched_current_investment_stage = this.optionsInvestmentStages.filter(obj => {
        // Check if the object has the specified key
        if (obj.hasOwnProperty("name")) {
          // Convert the value of the key to a string and to lowercase
          const value = String(obj["name"]).toLowerCase();
          // Check if the value includes any of the search strings
          return lowerSearchStrings.some(searchStr => value.includes(searchStr));
        }
        return false;
      });
      const matched_current_investment_stage_ids = matched_current_investment_stage.map(obj => {
        const value = obj["id"];
        // Attempt to convert to integer
        const intValue = parseInt(value, 10);
        // Return the integer if valid, otherwise return the original value
        return !isNaN(intValue) ? intValue : value;
      });
      this.current_investment_stage = matched_current_investment_stage;
      this.instance.current_investment_stage = matched_current_investment_stage_ids;
      this.is_stages_loading = false;
    },

    async searchCompanyNames(query, loading) {
      loading(true)
      this.companyNames = []
      try {
        const response = await this.$store.dispatch('SEARCH_COMPANY_NAMES', {query: query, pageSize: 10});
        this.companyNames = response.data
        return this.companyNames
      } catch (error) {
        console.error('Error searching tags:', error)
        return []
      } finally {
        loading(false)
      }
    },

    async searchTags(query, loading) {
      loading(true);
      this.tagOptions = [];
      try {
        const response = await this.$store.dispatch('SEARCH_TAGS', {query: query, pageSize: 50});
        this.tagOptions = response.data;
        return this.tagOptions;
      } catch (error) {
        console.error('Error searching tags:', error);
        return [];
      } finally {
        loading(false);
      }
    },
    async searchKeywords() {
      this.is_sectors_loading = true;
      const selected_industry_sector_tags = [];
      for (const tag of this.industry_sector_tags) {
        try {
          const result = await this.searchTags(tag, ()=>{});
          if (result.length > 0) {
            selected_industry_sector_tags.push(...result);
          } else {
            selected_industry_sector_tags.push(tag);
          }
        } catch (error) {
          console.error(`Error searching for tag ${tag}:`, error);
          // Decide how you want to handle errors. For now, we'll add the original tag
          selected_industry_sector_tags.push(tag);
        }
      }
      this.selected_industry_sector_tags = [...new Set(selected_industry_sector_tags)];
      this.is_sectors_loading = false;
    },
   addCountryOneLiner() {
      if (this.selectedRegion && this.countryOneLiner) {
        this.instance.country_one_liner.push({
          region: this.selectedRegion,
          country_one_liner: this.countryOneLiner,
        });
        // Reset after adding
        this.selectedRegion = null;
        this.countryOneLiner = '';
      } else {
        // Handle error
        this.errors.country_one_liner = ['Please select a country and enter a one-liner.'];
      }
    },
    removeCountryOneLiner(index) {
      this.instance.country_one_liner.splice(index, 1);
    },
    getRegionName(regionId) {
      const region = this.optionsRegions.find(c => c.id === regionId);
      return region ? region.name : regionId; // Fallback to code if not found
    },
    // searchTags(query, loading) {
    //   loading(true)
    //   this.$store.dispatch('SEARCH_TAGS', query)
    //     .then((response) => {
    //       this.tagOptions = response.data
    //     })
    //     .finally(() => {
    //       loading(false)
    //     })

    // },
    searchCities(query, loading) {
      const ids = this.instance.company_location_city
      loading(true)
      this.$store.dispatch('SEARCH_CITIES', { ids, query })
        .finally(() => {
          loading(false)
        })
    },
    setM2Ms() {
      this.instance.current_investment_stage = this.instance.current_investment_stage.map((x) => parseInt(x.id))
      this.instance.current_investor_type = this.instance.current_investor_type.map((x) => parseInt(x.id))
      this.instance.preferred_investor_type = this.instance.preferred_investor_type.map((x) => parseInt(x.id))
      this.instance.preferred_investor_region = this.instance.preferred_investor_region.map((x) => parseInt(x.id))
      this.instance.company_location_city = this.instance.company_location_city.map((x) => parseInt(x.id))

      this.$store.dispatch('SEARCH_CITIES', { ids: this.instance.company_location_city })

      const countryFields = [
        'registered_country',
        'team_location',
        'preferred_investor_location',
        'current_investors_location',
        'expansion_countries'
      ]

      for (const field of countryFields) {
        this.instance[field] = this.instance[field].map ? this.instance[field].map((x) => x.code) : this.instance[field].code
      }
    },
    onCapTableFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.instance.current_cap_table = files[0]
    },
    onPitchDeckFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.instance.pitch_deck_file = files[0]
    },
    onExecutiveSummaryFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.instance.executive_summary_file = files[0]
    },
    goView() {
      this.$router.push(`/startups/${this.instance.id}`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/companies?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    submit() {
      this.isSubmitting = true;
      this.errors = {};
      let instancePayload = { ...this.instance };
      if (this.instance.notable_institutional_investors && this.instance.notable_institutional_investors.join) {
        instancePayload.notable_institutional_investors = this.instance.notable_institutional_investors.join(', ');
      }

      const handleSuccess = (response) => {
        if (response && response.data) {
          this.$store.commit('SET_CURRENT_STARTUP', response.data);
          this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: response.data.id });
          this.$router.push('/investors/companies?match=true');
        } else {
          console.warn('Response or response.data is undefined');
        }
        this.isSubmitting = false;
      };

      const handleError = (error) => {
        console.error('Error in submit:', error);
        this.isSubmitting = false;
        if (error.response && error.response.data) {
          this.errors = error.response.data;
        } else {
          this.errors = { general: 'An unexpected error occurred. Please try again.' };
        }
      };

      const action = this.startupId ? 'UPDATE_STARTUP' : 'CREATE_STARTUP';

      this.$store.dispatch(action, instancePayload)
        .then(handleSuccess)
        .catch(handleError);
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  max-width: 80%; /* Adjust this value to your desired width */
  width: 900px; /* Or set a fixed width */
}

.modal-content {
  display: flex;
  flex-direction: column;
  max-height: 50%; /* Adjust this value to control the modal's height */
  overflow-y: auto;
}

.modal-scrollable-body{
  max-height: 60vh;
  overflow-y: auto;
}

.sectors-scrollable-body {
  max-height: 20vh;
  overflow-y: auto;
}

.stages-scrollable-select-field {
  max-height: 20vh;
  overflow-y: auto;
}

.modal-body {
  flex: 1;
}

.stage-body-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 20vh;
}

</style>