import axios from 'axios'

const state = {
  eventIndex: 0
}

const actions = {
}

const getters = { }

const mutations = {
  SET_PROTOTYPE_EVENT_INDEX (state, payload) {
    state.eventIndex = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
