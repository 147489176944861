<template>
  <header>
    <div class="header-loader" v-if="loaderActive">
      <div class="header-loader-inner" :style="`width: ${this.loaderProgress}%`"></div>
    </div>
    <div class="navbar-custom topnav-navbar">
      <div class="container-fluid d-flex justify-content-between">
        <div class="d-flex">
          <router-link :to="{ name: 'home' }" class="topnav-logo">
            <span class="topnav-logo-lg">
              <img src="/static/images/astel_logo.png" alt="" height="40">
            </span>
            <span class="topnav-logo-sm">
              <img src="/static/images/astel_logo.png" alt="" height="16">
            </span>
          </router-link>
          <div class="d-flex align-items-center ms-2 large" v-if="startup">
              <span class="nav-link pt-1">
              Startup:
              <router-link :to="{ name: 'startup_profile' }">
                <span class="align-middle d-none d-lg-inline-block fw-bold">{{ startup.name }}</span>
              </router-link>
              <router-link :to="{ name: 'startups_selector' }">
                <i class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
              </router-link>
              </span>
          </div>
          <div class="d-flex align-items-center ms-4 large" v-if="startup">
              <span class="nav-link pt-1">
              List:
              <router-link :to="{ name: 'investors_searches' }">
                <span class="align-middle d-none d-lg-inline-block fw-bold">Waisense: United Kingdom</span>
              </router-link>
              <router-link :to="{ name: 'investors_searches' }">
                <i class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
              </router-link>
              </span>
              <a href="#" class="btn btn-outline-primary ms-2 mt-2 mb-1">
                <i class="uil uil-bookmark"></i>
                Save
              </a>
          </div>
        </div>
        <ul class="topbar-menu d-flex align-items-center mb-0" style="list-style: none;">
          <li class="dropdown">
            <a class="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#" role="button"
              aria-haspopup="false" aria-expanded="false">
              <span class="account-user-avatar">
                <img src="/static/images/profile.png" alt="user-image" width="32" class="rounded-circle">
              </span>
              <span class="d-lg-flex flex-column d-none">
                <h5 class="my-0">{{ user.first_name }}</h5>
                <h6 class="my-0 fw-normal">{{ user.email }}</h6>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown" style="">
              <a href="#" class="dropdown-item" @click.prevent="goAccount">
                <i class="uil uil-user me-1"></i>
                <span>Account</span>
              </a>
              <a href="#" class="dropdown-item" @click.prevent="goFeed">
                <i class="uil uil-newspaper me-1"></i>
                <span>Feed</span>
              </a>
              <a href="#" class="dropdown-item" @click.prevent="goPrototype">
                <i class="uil uil-brush-alt me-1"></i>
                <span>Prototype</span>
              </a>
              <a href="#" class="dropdown-item" @click.prevent="goSettings">
                <i class="uil uil-cog me-1"></i>
                <span>GPT Settings</span>
              </a>
              <hr>
              <a href="#" class="dropdown-item" @click.prevent="logout">
                <i class="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'app-header',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      startup: state => state.startups.current,
      user: state => state.auth.user,
      loaderActive: state => state.loader.active,
      loaderProgress: state => state.loader.progress,
    })
  },
  methods: {
    goAccount() {
      this.$router.push({ name: 'account' })
    },
    goFeed() {
      this.$router.push({ name: 'feed' })
    },
    goPrototype() {
      this.$router.push({ name: 'prototype_feed' })
    },
    goSettings() {
      this.$router.push({ name: 'gpt-prompts' })
    },
    logout () {
      this.$router.push({ name: 'logout' })
    }
  }
}
</script>
