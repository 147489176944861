<template>
  <div class="row mt-3">
    <div class="col">
      <div
        class="card widget-flat cursor-pointer hover-opacity"
        :class="{ 'bg-secondary text-white': isCurrentRoute('prototype_feed') }"
        @click="$router.push({ name: 'prototype_feed' })"
      >
        <div class="card-body">
          <div class="float-end" style="margin-top: -10px">
            <i class="uil uil-newspaper largest"></i>
          </div>
          <h6 class="text-uppercase mt-0">Feed</h6>
          <h3 class="mt-2 mb-0">
            <p class="mb-0 text-muted">
              <span class="text-success me-2">+7</span>
            </p>
          </h3>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="card widget-flat cursor-pointer hover-opacity"
        :class="{ 'bg-secondary text-white': isCurrentRoute('prototype_matches') }"
        @click="$router.push({ name: 'prototype_matches' })"
      >
        <div class="card-body">
          <div class="float-end" style="margin-top: -10px">
            <i class="uil uil-users-alt larger"></i>
          </div>
          <div>
            <h6 class="text-uppercase mt-0">Matches</h6>
            <h3 class="mt-2 mb-0">
              138
              <span class="text-success me-2 large"><i class="mdi mdi-arrow-up-bold"></i>4</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="card widget-flat cursor-pointer hover-opacity"
        :class="{ 'bg-secondary text-white': isCurrentRoute('prototype_personalise') }"
        @click="$router.push({ name: 'prototype_personalise' })"
      >
        <div class="card-body">
          <div class="float-end">
            <i class="uil uil-comment-check larger"></i>
          </div>
          <h6 class="text-uppercase mt-0">Personalise</h6>
          <h3 class="mt-2 mb-0">
            138
            <!-- <span class="small text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 2.13%</span> -->
          </h3>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="card widget-flat cursor-pointer hover-opacity"
        :class="{ 'bg-secondary text-white': $route.name === 'prototype_outreach' }"
        @click="$router.push({ name: 'prototype_outreach' })"
      >
        <div class="card-body">
          <div class="float-end">
            <i class="uil uil-message larger"></i>
          </div>
          <h6 class="text-uppercase mt-0">Outreach</h6>
          <h3 class="mt-2 mb-0">
            122
            <span class="text-success me-2 large"><i class="mdi mdi-arrow-up-bold"></i>6</span>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'prototype-header',

  components: {
  },

  data() {
    return {
    }
  },

  computed: {
  },
  
  created() {
  },

  mounted() {
  },

  methods: {
    isCurrentRoute(routeName) {
      return this.$route.name === routeName
    }
  }
}
</script>
