<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div class="row mt-3" v-if="user && user.is_staff">
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_companies' }"
            @click="$router.push({ name: 'investors_companies' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-moneybag larger"></i>
              </div>
              <h6 class="text-uppercase mt-0" v-if="siteCompany.is_whitelabel">Your Investors</h6>
              <h6 class="text-uppercase mt-0" v-else>Investors</h6>
              <h3 class="mt-2 mb-0">
                <span v-if="companiesLoading">
                  <loader size="28px"></loader>
                </span>
                <span v-else-if="companiesLoaded && companies">{{ $filters.money(companies.count | 0) }}</span>
                <span v-else>--</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_contacts' }"
            @click="$router.push({ name: 'investors_contacts' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-user larger"></i>
              </div>
              <div>
                <h6 class="text-uppercase mt-0" v-if="siteCompany.is_whitelabel">Your Contacts</h6>
                <h6 class="text-uppercase mt-0" v-else>Contacts</h6>
                <h3 class="mt-2 mb-0">
                  <span v-if="contactsLoading">
                    <loader size="28px"></loader>
                  </span>
                  <span v-else-if="contactsLoaded && contacts">{{ $filters.money(contacts.count | 0) }}</span>
                  <span v-else>--</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-if="siteCompany.is_whitelabel">
          <div
              class="card widget-flat cursor-pointer hover-opacity"
              :class="{ 'bg-secondary text-white': $route.name === 'astel_campaigns' }"
              @click="$router.push({ name: 'astel_campaigns' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-monitor larger"></i>
              </div>
              <div>
                <h6 class="text-uppercase mt-0">Astel Network</h6>
                <h3 class="mt-2 mb-0">
                  <span v-if="astelContactsLoading">
                    <loader size="28px"></loader>
                  </span>
                  <span v-else-if="astelContactsLoaded && astelContacts">{{ $filters.money(astelContacts.count | 0) }}</span>
                  <span v-else>--</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_searches' }"
            @click="$router.push({ name: 'investors_searches' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-bookmark larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Saved List</h6>
              <h3 class="mt-2 mb-0 text-overflow">
                <span v-if="search">{{ $filters.truncate(search.saved_search_name || '', 15) }}</span>
                <span v-else>--</span>
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_personalise' }"
            @click="$router.push({ name: 'investors_personalise' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-comment-check larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Personalise</h6>
              <h3 class="mt-2 mb-0" v-if="search">
                <span v-if="personaliseContacts && personaliseContacts.count">{{ $filters.money(personaliseContacts.count) }}</span>
                <span v-else>
                  --
                </span>
                <!-- <span class="small text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 2.13%</span> -->
              </h3>
              <h3 class="mt-2 mb-0" v-else>
                --
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card widget-flat cursor-pointer hover-opacity"
            :class="{ 'bg-secondary text-white': $route.name === 'investors_outreach' }"
            @click="$router.push({ name: 'investors_outreach' })"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="uil uil-message larger"></i>
              </div>
              <h6 class="text-uppercase mt-0">Outreach</h6>
              <h3 class="mt-2 mb-0" v-if="search">
                <span v-if="outreach && outreach.count">{{ $filters.money(outreach.count) }}</span>
                <span v-else>
                  --
                </span>
                <!-- <span class="small text-success me-2"><i class="mdi mdi-arrow-up-bold"></i> 2.13%</span> -->
              </h3>
              <h3 class="mt-2 mb-0" v-else>
                --
              </h3>
            </div>
          </div>
        </div>
      </div>

      <template v-if="routeAllowed">
        <!-- Begin page -->
        <router-view></router-view>
        <!-- end wrapper-->
      </template>

      <template v-else-if="siteCompany.is_whitelabel">
        <div class="content-page">
          <div class="content">
            <div class="row" v-if="startup.matching_score_calculation_status!='COMPLETED'">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12" v-if="startup.matching_score_calculation_status == 'IN_PROGRESS'">
                      <h4>
                        Thank you for uploading your deal '{{ startup.name }}'.
                      </h4>
                      <p>
                        Our investment Analyst will review and come back to you with pre-analysed feedback and best match scores. You will be notified within the next 24 hours.
                      </p>
                    </div>
                    <div class="col-12" v-else>
                      <h4>
                        Thank you for uploading your deal '{{ startup.name }}'.
                      </h4>
                      <p>
                        IMPORTANT NOTICE: It is really important that you have filled in the detail of your profile correctly so that we can give you the best possible matching results. If you are 100% sure that you are finished with your profile, please confirm and authorise us to review and come back to you.
                      </p>
                      <p>
                        <loader v-if="isSubmitting" size="28px"></loader>
                        <button v-else class="btn btn-primary" @click="goGenerateMatchScore">Confirm & Authorise AI Match</button>
                      </p>
                      <p>
                        Our AI investment analyst will review and get back to you with pre-analysed feedback and best match scores. You will be notified within the next 24 hours.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <hr class="space space-md" />
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'dashboard',

  components: {
    'app-header': Header,
    'app-footer': Footer,
  },

  data() {
    return {
      setupDone: false,
      isSubmitting: false,
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
      startup: state => state.startups.current,
      startups: state => state.startups.startups,
      search: state => state.investors.currentSearch,
      companies: state => state.investors.companies,
      companiesLoading: state => state.investors.companiesLoading,
      companiesLoaded: state => state.investors.companiesLoaded,
      searches: state => state.investors.searches,
      contacts: state => state.investors.contacts,
      astelContacts: state => state.astelInvestors.contacts,
      contactsLoading: state => state.investors.contactsLoading,
      astelContactsLoading: state => state.astelInvestors.contactsLoading,
      contactsLoaded: state => state.investors.contactsLoaded,
      astelContactsLoaded: state => state.astelInvestors.contactsLoaded,
      personaliseContacts: state => state.personalise.contacts,
      outreach: state => state.outreach.contacts,
    }),
    routeAllowed() {
      // checks if the product is ready for whitelabel clients
      if (
        this.$route.path.startsWith('/me') ||
        this.$route.path.startsWith('/startup')
      ) {
        return true
      }

      if (
        this.siteCompany && !this.siteCompany.is_whitelabel
      ) {
        return true
      }

      if (
        this.startup &&
        this.startup.matching_score_calculation_status == 'COMPLETED'
      ) {
        return true
      }

      return false
    }
  },
  
  created() {
    this.$store.commit('SET_COMPANIES_LOADING', false)
    this.$store.commit('SET_CONTACTS_LOADING', false)
  },

  mounted() {
    if (!this.startups) {
      this.$store.dispatch('LOAD_STARTUPS')
        .then(() => {
          if (!this.startup) {
            if (this.user && !this.user.startup && this.startups && this.startups.length) {
              this.$router.push({ name: 'startups_selector' })
            } else if (this.user && this.user.startup) {
              this.$router.push({ name: 'startup_profile' })
            } else {
              this.$router.push({ name: 'startup_profile_add' })
            }
            return
          }
        })
    }

    if (this.startup) {
      this.$store.dispatch('RELOAD_CURRENT_STARTUP')
    }

    // load search options
    Promise.all([
      this.$store.dispatch('LOAD_SEARCH_FIELDS'),
      this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
      this.$store.dispatch('LOAD_STARTUPS')
    ])

    // this.loadStartupContent()
  },

  methods: {
    loadStartupContent() {
      return Promise.all([
        this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: this.startup.id }),
        this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id }),
      ])
    },

    goGenerateMatchScore() {
      if (confirm('Are you sure you want to trigger generation of results for this startup?')) {
        this.isSubmitting = true
        this.$store.dispatch('GENERATE_MATCH_SCORE', this.instance.id)
          .then(() => {
            this.$store.dispatch('RELOAD_CURRENT_STARTUP')
          })
          .finally(() => {
            this.isSubmitting = false
          })
      }
    },
  },

  watch: {
    'startup': {
      handler: function (val, oldVal) {
        if (val) {
          this.loadStartupContent()
        }
      }
    }
  }
}
</script>
