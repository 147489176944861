<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">
      <prototype-header></prototype-header>
      <div v-if="contact">
        <div class="row">
          <div class="col pe-0">

            <div class="card">
              <div class="card-body">
                
                <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                  <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                    <i class="uil uil-pen me-1"></i>
                    <span>Edit</span>
                  </a>
                  <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                    <i class="uil uil-trash me-1"></i>
                    <span>Delete</span>
                  </a>
                </div>
                
                <h4 class="header-title mb-3">Contact Profile</h4>

                <h3>{{ contact.first_name }} {{ contact.last_name }}</h3>

                <div class="mb-3">
                  <span class="badge badge-warning-lighten">Contacted</span>
                  <span class="badge badge-success-lighten ms-2">Replied</span>
                </div>

                <div class="row">
                  <div class="col-md-8">
                    <p class="mb-2">
                      <span class="fw-bold me-2">Name:</span>
                      {{ contact.first_name }} {{ contact.last_name }}
                    </p>

                    <p class="mb-2" v-if="contact.company">
                      <span class="fw-bold me-2">Company:</span>
                      <router-link :to="`/investors/companies/${contact.company.id}/`">
                        {{ contact.company.name }}
                      </router-link>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Email:</span>
                      {{ contact.email }}
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Phone Number:</span>
                      {{ contact.phone_number }}
                    </p>

                    <p class="mb-2"><span class="fw-bold me-2">Title:</span>
                      <template v-if="contact.title && contact.title.length">
                        <span v-for="item in contact.title" :key="item">
                          <span v-if="item" class="me-2">{{ item.name }}</span>
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">LinkedIn:</span>
                      {{contact.linkedIn_URL}}
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">About:</span>
                      {{contact.linkedIn_about}}
                    </p>

                    <div class="mb-2" v-if="contact.startups.length">
                      <span>
                        <h4 class="header-title">Deals shared:</h4>
                      </span>

                      <ul>
                        <li v-for="startup in contact.startups">
                          {{ startup.name }}<br>
                          <span class="small" v-for="(campaign, index) in startup.campaigns">
                            {{ campaign }}<template v-if="index + 1 !== startup.campaigns.length">, </template>
                          </span>
                        </li>
                      </ul>
                    </div>

                    <span>
                      <h4 class="mt-4 header-title">Our summary:</h4>
                    </span>

                    <p class="mb-2">
                      <ul>
                        <li>
                          Most invested in:
                          <ul>
                            <li><span class="badge badge-outline-primary rounded-pill">SAAS</span> 63%</li>
                            <li><span class="badge badge-outline-primary rounded-pill">Finance</span> 13%</li>
                            <li><span class="badge badge-outline-primary rounded-pill">Web3</span> 11%</li>
                          </ul>
                        </li>
                        <li class="mt-2">
                          Most discussed recently:
                          <ul>
                            <li><span class="badge badge-outline-danger rounded-pill">AI</span> 81%</li>
                            <li><span class="badge badge-outline-danger rounded-pill">Crypto</span> 9%</li>
                          </ul>
                        </li>
                      </ul>
                    </p>

                    <span>
                      <h4 class="mt-4 header-title">Insights:</h4>
                    </span>

                    <p class="mb-2" v-if="contact.insights && contact.insights.length && !contact.insights_loading">
                      <div class="mb-3">
                        Filter:
                        <span class="badge badge-dark-lighten cursor-pointer ms-2" @click.prevent="this.insightType = null" :class="this.insightType === null ? 'border border-dark': ''">All</span>
                        <span class="badge badge-primary-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'Webpage'" :class="this.insightType === 'Webpage' ? 'border border-primary': ''">Webpage</span>
                        <span class="badge badge-info-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'LinkedIn'" :class="this.insightType === 'LinkedIn' ? 'border border-dark': ''">LinkedIn</span>
                        <span class="badge badge-danger-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'Podcast'" :class="this.insightType === 'Podcast' ? 'border border-dark': ''">Podcast</span>
                        <span class="badge badge-success-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'Newsletter'" :class="this.insightType === 'Newsletter' ? 'border border-dark': ''">Newsletter</span>
                        <span class="badge badge-dark-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'Twitter'" :class="this.insightType === 'Twitter' ? 'border border-dark': ''">Twitter</span>
                      </div>
                      <div class="timeline-alt py-0" v-if="Array.isArray(contact.insights)">
                        <div class="timeline-item" v-for="item in filteredInsights">
                          <i class="mdi timeline-icon" :class="getInsightIconClasses(item)"></i>
                            <div class="timeline-item-info">
                                <a href="javascript:void(0);" class="fw-bold mb-1 d-block" :class="getInsightClass(item)">{{ item.title }}</a>
                                {{ item.content }}<br>
                                <template v-if="item.link">
                                  [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                                </template>
                                <p class="mb-0 pb-2">
                                  <template v-if="item.type === 'Newsletter'">
                                    <small class="text-muted">Email to ai@astelventures.com on {{ $filters.dateformat(item.timestamp) }}</small>
                                  </template>
                                  <template v-else>
                                    <small class="text-muted" v-if="item.timestamp">{{ $filters.dateformat(item.timestamp) }}</small>
                                    <small class="text-muted" v-else>Recently</small>
                                  </template>
                                </p>
                            </div>
                        </div>
                        <div v-if="!filteredInsights.length" class="text-muted">
                          No matching insights found yet.
                        </div>
                      </div>
                      <div v-else class="pre">
                        {{ contact.insights }}
                      </div>
                    </p>

                    <div v-if="contact.insights_timestamp" class="small text-muted mt-2">
                      Last updated: {{ $filters.dateformat(contact.insights_timestamp) }}
                    </div>
                    <!-- <p v-else-if="!(loadingInsights || contact.insights_loading)">
                      ---
                    </p> -->

                    <br><br>

                    <div class="py-2" v-if="loadingInsights || contact.insights_loading">
                      <loader size="48px"></loader>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <h4 class="header-title mb-3">Career Highlights</h4>

                    <div class="timeline-alt py-0">
                      <div class="timeline-item" v-for="item in selectedCareer">
                        <i class="mdi timeline-icon text-primary bg-primary-lighten" :class="item.icon"></i>
                          <div class="timeline-item-info">
                            <div class="mb-1">
                              {{ item.title }} at <a href="javascript:void(0);" class="fw-bold d-block text-primary">{{ item.company }}</a>
                              <span class="small" v-if="item.tags">{{ item.tags }}</span>
                            </div>
                            {{ item.years }}<br>
                            <small class="text-muted" v-if="item.connections && item.connections.length">
                              Together with
                              <span v-for="(c, index) in item.connections" class="pe-1">
                                <a :href="`/pro/contacts/${c.id}/`">{{ c.name }}</a>
                                <template v-if="index + 1 !== item.connections.length">, </template>
                              </span>
                            </small>
                            <br><br>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- end col -->
        </div>

        <div class="row">

          <div class="col-lg-6" v-if="contact.company">
            <div class="card">
              <div class="card-body">
                <h4 class="header-title mb-3">Company Profile</h4>

                <ul class="list-unstyled mb-0">
                  <li>
                    <h3 class="mb-2">
                      {{ contact.company.name }}
                      <span
                        v-if="contact.company.unsubscribed === 'YES'"
                        class="small fw-bold me-2 mb-2 "
                        style="color:red"
                      >
                        UNSUBSCRIBED
                      </span>
                    </h3>

                    <p class="mb-2">
                      {{ contact.company.short_description }}
                    </p>

                    <p class="mb-2">
                      <a href="http://{{ contact.company.website }}" target="_blank">{{ contact.company.website }}</a>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Investor location:</span>
                      <template v-if="contact.company.investor_location && contact.company.investor_location.length">
                        <span v-for="item in contact.company.investor_location" :key="item" class="d-block pre">
                          <template v-if="item">
                            <country-flag :country='item.code' size='small' />
                            {{ item.name }}
                          </template>
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2" v-if="contact.company.investor_location_preferences">
                      <span class="fw-bold me-2">Investor Location Preferences:</span>
                      {{ contact.company.investor_location_preferences }}
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Investor cities:</span>
                      <template v-if="contact.company.investor_city && contact.company.investor_city.length">
                        <span v-for="item in contact.company.investor_city" :key="item">
                          <template v-if="item">{{ item.name }}</template>
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Sectors:</span>
                      {{ contact.company.investment_sectors }}
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Stages:</span>
                      <template v-if="contact.company.investment_stages && contact.company.investment_stages.length">
                        <span v-for="stage in contact.company.investment_stages" :key="stage" class="d-block pre">
                          {{ stage.name }}
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Type:</span>
                      <template v-if="contact.company.investor_type && contact.company.investor_type.length">
                        <span v-for="item in contact.company.investor_type" :key="item" class="d-block pre">
                          {{ item.name }}
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Countries invested in:</span>
                      <template v-if="contact.company.investment_countries && contact.company.investment_countries.length">
                        <span v-for="item in contact.company.investment_countries" :key="item" class="d-block pre">
                          <template v-if="item">
                            {{ item }}
                          </template>
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2">
                      <span class="fw-bold me-2">Tags:</span>
                      <template v-if="contact.company.industry_sector_tags && contact.company.industry_sector_tags.length">
                        <span v-for="item in contact.company.industry_sector_tags" :key="item" class="d-block pre">
                          {{ item }}
                        </span>
                      </template>
                      <template v-else>
                        —
                      </template>
                    </p>

                    <p class="mb-2" v-if="contact.company.profitable">
                      <span class="fw-bold me-2">Profitable:</span>
                      {{ contact.company.profitable }}
                    </p>

                    <p class="mb-2" v-if="contact.company.invest_in_house_technology">
                      <span class="fw-bold me-2">Invest in house technology:</span>
                      {{ contact.company.invest_in_house_technology }}
                    </p>

                    <p class="mb-2" v-if="contact.company.strategic_value">
                      <span class="fw-bold me-2">Strategic Value:</span>
                      {{ contact.company.strategic_value }}
                    </p>

                    <p class="mb-2" v-if="contact.company.revenue_preference">
                      <span class="fw-bold me-2">Revenue Preference:</span>
                      {{ contact.company.revenue_preference }}
                    </p>

                    <p class="mb-2" v-if="contact.company.investment_partners_location">
                      <span class="fw-bold me-2">Investment partners location:</span>
                      <span v-for="item in contact.company.investment_partners_location" :key="item" class="d-block pre">
                        <template v-if="item">
                          {{ item }}
                        </template>
                      </span>
                    </p>

                    <p class="mb-2" v-if="contact.company.historic_investments">
                      <span class="fw-bold me-2">Historic Investments:</span>
                      {{ $filters.money(contact.company.historic_investments) }}
                    </p>

                    <p class="mb-2" v-if="contact.company.preferred_investor_region">
                      <span class="fw-bold me-2">Preferred Investor Region:</span>
                      {{ contact.company.preferred_investor_region }}
                    </p>

                    <p class="mb-0" v-if="contact.company.profile_link">
                      <span class="fw-bold me-2">Profile Link:</span>
                      <a :href="contact.company.profile_link" target="_blank">{{ contact.company.profile_link }}</a>
                    </p>

                  </li>
                </ul>

              </div>
            </div>
          </div> <!-- end col -->
          
          <div class="col-lg-6">
              <!-- if object.Notes %}-->
              <div class="card">
                  <div class="card-body">
                      <h4 class="header-title mb-3">Notes</h4>
                      
                      <div class="mb-2" v-for="(note, index) in notes">
                        <template v-if="!isAddingNote && this.note.id === note.id">
                          <div class="form-group mb-3">
                            <textarea
                              id="id_notes"
                              type="text"
                              name="notes"
                              class="form-control"
                              rows="3"
                              required
                              v-model="note.notes"
                            >
                            </textarea>

                            <p v-if="errors.notes && errors.notes.length">
                              <span v-for="error in errors.notes" :key="error" class="text-danger">{{ error }}</span>
                            </p>
                          </div>

                          <div class="form-group mb-2">
                            <label for="id_current_investors_location">Contact Type:</label>

                            <v-select
                              name="contact_type"
                              v-model="note.contact_type"
                              :options="optionsContactTypes"
                              :reduce="x => x.id"
                              label="name"
                              multiple
                            >
                            </v-select>

                            <p v-if="errors.current_investors_location && errors.current_investors_location.length">
                              <span v-for="error in errors.current_investors_location" :key="error" class="text-danger">{{ error }}</span>
                            </p>
                          </div>

                          <button class="btn btn-outline-primary me-3" :class="{ disabled: isSubmittingNote }" @click.prevent="goCancelEditNote">
                            Cancel
                          </button>
                          <button class="btn btn-primary" :class="{ disabled: isSubmittingNote }" @click.prevent="submitNote">
                            Save Changes
                          </button>
                        </template>
                        <template v-else>
                          <p class="mb-1">{{ note.notes }}</p>

                          <figcaption>
                              {{ note.created_by.first_name }} {{ note.created_by.last_name }}
                              -
                              <span v-for="(type, index) in note.contact_type" :key="index">
                                <i class="uil uil-user" v-if="type.name === 'Met In Person'"></i>
                                <i class="uil uil-phone" v-if="type.name === 'Call'"></i>
                                <i class="uil uil-envelope" v-if="type.name === 'Email'"></i>
                                {{ type.name }}<template v-if="index + 1 !== note.contact_type.length">, </template>
                              </span>
                              - {{ note.created_date }}
                              -
                              <cite>
                                  <a href="#" class="uil uil-pen me-1" @click.prevent="goEditNote(note)"></a>
                                  <a href="#" class="ui uil-trash-alt me-1" @click.prevent="goDeleteNote(note)"></a>
                                  <b v-if="index + 1 === notes.length">(Last Met)</b>
                              </cite>
                          </figcaption>
                        </template>
                          
                        <hr class="col-sm-6 col-12">
                      </div>

                      <div class="col-sm-6 col-12" v-if="isAddingNote">
                        <h5>Add Note:</h5>

                        <div class="form-group mb-3">
                          <textarea
                            id="id_notes"
                            type="text"
                            name="notes"
                            class="form-control"
                            rows="3"
                            required
                            v-model="note.notes"
                          >
                          </textarea>

                          <p v-if="errors.notes && errors.notes.length">
                            <span v-for="error in errors.notes" :key="error" class="text-danger">{{ error }}</span>
                          </p>
                        </div>

                        <div class="form-group mb-2">
                          <label for="id_current_investors_location">Contact Type:</label>

                          <v-select
                            name="contact_type"
                            v-model="note.contact_type"
                            :options="optionsContactTypes"
                            :reduce="x => x.id"
                            label="name"
                            multiple
                          >
                          </v-select>

                          <p v-if="errors.current_investors_location && errors.current_investors_location.length">
                            <span v-for="error in errors.current_investors_location" :key="error" class="text-danger">{{ error }}</span>
                          </p>
                        </div>

                        <button class="btn btn-outline-primary me-3" :class="{ disabled: isSubmittingNote }" @click.prevent="isAddingNote = false">
                          Cancel
                        </button>
                        <button class="btn btn-primary" :class="{ disabled: isSubmittingNote }" @click.prevent="submitNote">
                          Add Note
                        </button>
                      </div>

                      <a v-if="!isAddingNote" href="#" class="btn btn-outline-primary" @click.prevent="goAddNote">
                        <span>Add Note</span>
                      </a>
                  
                  </div>
              </div>
              <!-- endif %}-->
          </div> <!-- end col -->

        </div>
          
      </div>
      <div v-else class="text-center">
        <loader></loader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Prototype/AppHeader.vue'
import Footer from '@/components/Layout/Footer.vue'

import PrototypeHeader from '@/components/Prototype/Header.vue'

export default {
  name: 'contact',

  components: {
    'app-header': Header,
    'app-footer': Footer,
    'prototype-header': PrototypeHeader,
  },

  data() {
    return {
      contact: null,
      notes: [],
      isAddingNote: false,
      isSubmittingNote: false,
      pollTimer: null,
      loadingInsights: false,
      insightType: null,
      note: {},
      errors: {},
      careerLists: [
        [
          {
            company: 'Cambridge Innovation Capital',
            title: 'Partner',
            years: '2021 - Present',
            icon: 'mdi-bank'
          },
          {
            company: 'Unlikely AI',
            title: 'Investor',
            tags: 'Artificial Intelligence, UK / London',
            years: '2019 - 2021',
            connections: [
              {
                id: 50854,
                name: 'Richard Bolton @ Octopus Ventures'
              },
            ],
            icon: 'mdi-hand-coin'
          },
          {
            company: 'Discuss.io',
            title: 'Board Member',
            tags: 'Customer Service, Market Research, Meeting Software, Video Conferencing',
            years: '2017 - 2019',
            icon: 'mdi-account-tie-voice'
          },
          {
            company: 'Seldon',
            title: 'Board Member',
            tags: 'Artificial Intelligence, Open Source, UK / London',
            years: '2015 - 2017',
            icon: 'mdi-account-tie-voice'
          },
          {
            company: 'Cognition X',
            title: 'Investor',
            tags: 'Artificial Intelligence, Enteprise Software',
            years: '2010 - 2014',
            connections: [
              {
                id: 19847,
                name: 'Gary Gauba @ CXO Fund'
              },
            ],
            icon: 'mdi-hand-coin'
          },
        ],
        [
          {
            company: 'Tech Innovations Ltd.',
            title: 'Chief Technology Officer',
            tags: 'Technology, Innovation, Product Development',
            years: '2015 - 2019',
            connections: [
              {
                id: 78901,
                name: 'Alexandra Turner @ Venture Nexus'
              },
            ],
            icon: 'mdi-cogs'
          },
          {
            company: 'Global Investment Partners',
            title: 'Senior Analyst',
            tags: 'Finance, Investment Strategy, Market Analysis',
            years: '2013 - 2015',
            icon: 'mdi-chart-line'
          },
          {
            company: 'InnovateTech Solutions',
            title: 'Software Engineer',
            tags: 'Software Development, Coding, Algorithms',
            years: '2011 - 2013',
            icon: 'mdi-code-braces'
          },
          {
            company: 'Venture Nexus',
            title: 'Intern',
            years: '2010 - 2011',
            icon: 'mdi-briefcase-search'
          },
          {
            company: 'Strategic Consulting Group',
            title: 'Business Analyst',
            tags: 'Business Strategy, Market Research, Consulting',
            years: '2008 - 2010',
            icon: 'mdi-account-tie'
          }, 
        ],
        [
          {
            company: 'Emerging Tech Innovations',
            title: 'Chief Innovation Officer',
            tags: 'Emerging Technologies, Research, Product Innovation',
            years: '2020 - Present',
            connections: [
              {
                id: 98765,
                name: 'Martin Thompson @ Tech Leaders Forum'
              },
            ],
            icon: 'mdi-lightbulb-on'
          },
          {
            company: 'Digital Ventures Corporation',
            title: 'Strategic Partnerships Manager',
            tags: 'Digital Strategy, Partner Development, Ecosystem Building',
            years: '2018 - 2020',
            icon: 'mdi-handshake'
          },
          {
            company: 'Data Insights Co.',
            title: 'Data Scientist',
            tags: 'Data Analysis, Machine Learning, Predictive Modeling',
            years: '2016 - 2018',
            icon: 'mdi-chart-pie'
          },
          {
            company: 'Innovative Marketing Solutions',
            title: 'Marketing Manager',
            tags: 'Branding, Digital Marketing, Campaign Strategy',
            years: '2014 - 2016',
            connections: [
              {
                id: 45678,
                name: 'Emily Davis @ Creative Minds Agency'
              },
            ],
            icon: 'mdi-bullhorn'
          },
          {
            company: 'Tech Start Innovate',
            title: 'Co-Founder',
            tags: 'Startup Leadership, Business Development, Technology Innovation',
            years: '2004 - 2006',
            connections: [
              {
                id: 56789,
                name: 'Daniel Turner @ Startup Connect'
              },
            ],
            icon: 'mdi-rocket'
          },
        ],
        [
          {
            company: 'Financial Analytics Ltd.',
            title: 'Financial Analyst',
            tags: 'Financial Modeling, Investment Analysis, Risk Management',
            years: '2002 - 2004',
            icon: 'mdi-currency-usd'
          },
          {
            company: 'StartUp Accelerator',
            title: 'Entrepreneur in Residence',
            tags: 'Startup Incubation, Mentorship, Innovation',
            years: '2006 - 2008',
            connections: [
              {
                id: 12345,
                name: 'Jessica Chen @ Innovation Hub'
              },
            ],
            icon: 'mdi-lightbulb-on'
          },
          {
            company: 'CreatorIQ',
            title: 'Investor',
            tags: 'Advertising, Brand Marketing, SAAS, CA / Los Angeles',
            years: '2019 - 2021',
            icon: 'mdi-account-tie-voice'
          },
          {
            company: 'Unilever Ventures',
            title: 'Director',
            years: '2010 - 2022',
            connections: [
              {
                id: 48790,
                name: 'Rachel Harris'
              },
              {
                id: 6196,
                name: 'Keith Weed'
              }
            ],
            icon: 'mdi-bank'
          },
          {
            company: 'Startup Launchpad',
            title: 'Business Development Manager',
            tags: 'Startup Growth, Sales, Market Expansion',
            years: '2006 - 2008',
            connections: [
              {
                id: 34567,
                name: 'David Miller @ Growth Catalysts'
              },
            ],
            icon: 'mdi-rocket-launch-outline'
          },

        ],
        [
          {
            company: 'Tech Solutions Hub',
            title: 'Lead Software Engineer',
            tags: 'Software Development, Team Leadership, Project Management',
            years: '2017 - 2021',
            icon: 'mdi-code-tags'
          },
          {
            company: 'Digital Innovations Agency',
            title: 'Creative Director',
            tags: 'Digital Design, User Experience, Creative Strategy',
            years: '2015 - 2017',
            connections: [
              {
                id: 87654,
                name: 'Olivia Adams @ DesignElite'
              },
            ],
            icon: 'mdi-palette'
          },
          {
            company: 'Venture Catalysts',
            title: 'Investment Analyst',
            tags: 'Venture Capital, Due Diligence, Deal Sourcing',
            years: '2013 - 2015',
            icon: 'mdi-briefcase-check'
          },
          {
            company: 'InnovateTech Labs',
            title: 'Research Scientist',
            tags: 'Research, Scientific Experimentation, Data Analysis',
            years: '2010 - 2013',
            icon: 'mdi-microscope'
          },
          {
            company: 'Marketing Dynamics Inc.',
            title: 'Marketing Coordinator',
            tags: 'Marketing Strategy, Campaign Coordination, Analytics',
            years: '2008 - 2010',
            icon: 'mdi-bullhorn-outline'
          },
        ],
        [
          {
            company: 'Tech Innovations Hub',
            title: 'Innovation Strategist',
            tags: 'Innovation Management, Technology Strategy, Future Trends',
            years: '2014 - 2016',
            connections: [
              {
                id: 56789,
                name: 'Sophia Thompson @ Tech Visionaries'
              },
            ],
            icon: 'mdi-lightbulb-on'
          },
          {
            company: 'Global Investments Group',
            title: 'Investment Manager',
            tags: 'Portfolio Management, Due Diligence, Investment Strategy',
            years: '2008 - 2010',
            icon: 'mdi-briefcase-check'
          },
          {
            company: 'Digital Marketing Solutions',
            title: 'Marketing Director',
            tags: 'Digital Strategy, Campaign Management, Branding',
            years: '2016 - 2018',
            connections: [
              {
                id: 87654,
                name: 'Alexandra Davis @ Digital Dynamics Agency'
              },
            ],
            icon: 'mdi-bullhorn-outline'
          },
          {
            company: 'AI Startup Ventures',
            title: 'Co-Founder',
            tags: 'Startup Leadership, Artificial Intelligence, Product Development',
            years: '2020 - Present',
            connections: [
              {
                id: 98765,
                name: 'Chris Johnson @ AI Innovators'
              },
            ],
            icon: 'mdi-rocket-launch-outline'
          },
          {
            company: 'Tech Mentorship Network',
            title: 'Mentor',
            tags: 'Entrepreneurship, Mentorship, Tech Ecosystem',
            years: '2012 - 2016',
            connections: [
              {
                id: 23456,
                name: 'Jonathan Kim @ Startup Nexus'
              },
            ],
            icon: 'mdi-account-multiple-check'
          },
        ],
        [
          {
            company: 'Tech Solutions Ltd.',
            title: 'Lead Software Engineer',
            tags: 'Software Development, Team Leadership, Agile Methodology',
            years: '2016 - 2020',
            connections: [
              {
                id: 34567,
                name: 'Daniel White @ CodeCrafters'
              },
            ],
            icon: 'mdi-code-tags'
          },
          {
            company: 'InnovateTech Labs',
            title: 'Research Scientist',
            tags: 'Scientific Research, Data Analysis, Experimental Design',
            years: '2004 - 2006',
            icon: 'mdi-microscope'
          },
          {
            company: 'Digital Marketing Ventures',
            title: 'Digital Marketing Specialist',
            tags: 'SEO, Social Media Marketing, Content Strategy',
            years: '2007 - 2012',
            connections: [
              {
                id: 78901,
                name: 'Emily Baker @ Digital Pioneers'
              },
            ],
            icon: 'mdi-bullhorn-outline'
          },
          {
            company: 'Blockchain Innovations',
            title: 'Blockchain Developer',
            tags: 'Smart Contracts, DLT, Cryptocurrency',
            years: '2022 - Present',
            connections: [
              {
                id: 89012,
                name: 'Michael Turner @ CryptoExplorers'
              },
            ],
            icon: 'mdi-block-helper'
          },
          {
            company: 'HealthTech Solutions',
            title: 'Healthcare IT Consultant',
            tags: 'Health Informatics, EHR Implementation, Telehealth',
            years: '2012 - 2014',
            icon: 'mdi-hospital-building'
          },
        ]
      ],
      selectedCareer: [],
    }
  },

  computed: {
    ...mapState({
      optionsContactTypes: state => state.investors.contactTypes
    }),
    contactId() {
      return this.$route.params.id || (this.contact ? this.contact.id : null)
    },
    filteredInsights() {
      return this.contact.insights.filter((item) => {
        if (this.insightType) {
          if (this.insightType === 'Webpage') {
            return !item.type || item.type === this.insightType
          }
          return item.type === this.insightType
        }
        return true
      })
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)

    this.selectRandomCareerList(); 

    if (this.contactId) {
      Promise.all([
        this.$store.dispatch('LOAD_CONTACT', this.contactId),
        this.$store.dispatch('LOAD_CONTACT_NOTES', this.contactId),
        this.$store.dispatch('LOAD_CONTACT_TYPES')
      ])
        .then((response) => {
          this.contact = response[0].data
          this.notes = response[1].data

          if (this.contact.insights_loading) {
            this.poll()
          }

          this.contact.insights = this.addFakeInsights(this.contact.insights)
        })
    }
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {
    selectRandomCareerList() {
      const randomListIndex = Math.floor(Math.random() * this.careerLists.length);
      const randomList = this.careerLists[randomListIndex];
      const numberOfItemsToShow = Math.floor(Math.random() * randomList.length) + 1;
      this.selectedCareer = [...randomList].sort(() => Math.random() - 0.5).slice(0, numberOfItemsToShow);
    },

    load() {
      return this.$store.dispatch('LOAD_CONTACT', this.contactId)
    },

    goView() {
      this.$router.push(`/contacts/${this.contact.id}`)
    },

    goEdit() {
      this.$router.push(`/contacts/${this.contact.id}/edit`)
    },

    goDelete() {
      this.$router.push(`/contacts/${this.contact.id}/delete`)
    },

    addFakeInsights(insights) {
      if (!insights) return []
      const insight1 = {
        title: 'Newsletter: Congrats Ray Rothrock and NVCA!',
        content: 'Partner joined with the NVCA to ring the NYSE Closing Bell on December 6, celebrating the 50th anniversary of the NVCA',
        link: null,
        timestamp: (new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000)).toISOString(),
        type: 'Newsletter'
      }
      const insight2 = {
        title: 'Newsletter: New round by a portfolio company',
        content: 'Base Operations (Pilot Fund and Fund I), an enterprise platform for physical security operations, has announced their $9m Series A round. ',
        link: null,
        timestamp: (new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).toISOString(),
        type: 'Newsletter'
      }
      const insight3 = {
        title: 'Newsletter: The SANS Institute Award',
        content: 'The SANS Institute has honored two of our colleagues with significant awards in their Difference Makers Awards ceremony.',
        link: null,
        timestamp: (new Date(new Date().getTime() - 9 * 24 * 60 * 60 * 1000)).toISOString(),
        type: 'Newsletter'
      }
      // Twitter
      const twitterLists = [
        [
          {
            title: 'Twitter: Deeptech in Italy',
            content: 'Posted about an upcoming event spotlighting Italian Deeptech: machinery, manufacturing, space tech, clean tech, and automation.',
            link: 'https://twitter.com/360CP/status/1713812785589633224',
            timestamp: '2023-10-16T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: "Twitter: It's time to build, but greener",
            content: 'Meeting with more and more entrepreneurs tackling the CO2 emissions of the Built Environment in Europe.',
            link: 'https://twitter.com/360CP/status/1702300362466631876',
            timestamp: '2023-09-14T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Congratulating @allstackapp on a new $12.3m Series A round',
            content: '@allstacksapp raised a $12.3M Series A to help more teams mitigate software development risk with data! Excited to invest alongside great partners.',
            link: 'https://twitter.com/S3ventures/status/1585655991730855938',
            timestamp: '2022-10-27T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Portfolio company Yuvo Health milestone',
            content: 'Congrats for achieving this massive milestone as they empower federally qualified health centers (FQHCs) to further quality care and value-based care arrangements',
            link: 'https://twitter.com/K50Ventures/status/1666880449921024008',
            timestamp: '2023-06-08T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: New trends in AI and Machine Learning',
            content: 'Attended a conference highlighting the latest trends in AI and Machine Learning. Exciting times ahead for technology enthusiasts!',
            link: 'https://twitter.com/360CP/status/1685921764568362496',
            timestamp: '2023-07-12T09:03:00.000Z',
            type: 'Twitter'
          },
        ],
        [
          {
            title: 'Twitter: AI in Healthcare',
            content: 'Shared thoughts on the transformative impact of AI in healthcare, discussing innovations and challenges in the field.',
            link: 'https://twitter.com/360CP/status/1672058390324566784',
            timestamp: '2023-06-20T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Future of Work',
            content: 'Explored insights on the future of work and how technology is reshaping the way we collaborate and innovate in the workplace.',
            link: 'https://twitter.com/360CP/status/1659207180917328896',
            timestamp: '2023-05-15T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Investing in Sustainable Tech',
            content: 'Discussed the importance of investing in sustainable technology and supporting startups driving positive environmental impact.',
            link: 'https://twitter.com/360CP/status/1647324056723456512',
            timestamp: '2023-04-10T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Emerging Tech Trends',
            content: 'Highlighted emerging technology trends, including blockchain, IoT, and quantum computing, shaping the future of innovation.',
            link: 'https://twitter.com/360CP/status/1634402734012346880',
            timestamp: '2023-03-05T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Women in Tech',
            content: 'Celebrated the contributions of women in the tech industry, discussing challenges, opportunities, and inspiring success stories.',
            link: 'https://twitter.com/360CP/status/1570016102156139520',
            timestamp: '2022-10-01T09:03:00.000Z',
            type: 'Twitter'
          },
        ],
        [
          {
            title: 'Twitter: AI in Education',
            content: 'Explored the role of artificial intelligence in transforming education, discussing innovations and challenges in the sector.',
            link: 'https://twitter.com/360CP/status/1621481340421232640',
            timestamp: '2023-02-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Tech for Social Impact',
            content: 'Advocated for the use of technology as a force for social good, sharing insights on impactful projects and initiatives.',
            link: 'https://twitter.com/360CP/status/1608670039789876736',
            timestamp: '2023-01-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Future of Fintech',
            content: 'Discussed emerging trends in the fintech industry, including blockchain, digital payments, and the future of financial innovation.',
            link: 'https://twitter.com/360CP/status/1595758727244560896',
            timestamp: '2022-12-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Innovation in Healthcare',
            content: 'Shared insights on the latest innovations in healthcare technology, highlighting advancements in patient care and digital health.',
            link: 'https://twitter.com/360CP/status/1582837414700845056',
            timestamp: '2022-11-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Sustainable Innovations',
            content: 'Explored innovations in sustainability, from renewable energy to eco-friendly products, and the role of technology in driving positive change.',
            link: 'https://twitter.com/360CP/status/1557094789610811392',
            timestamp: '2022-09-01T09:03:00.000Z',
            type: 'Twitter'
          },
        ],
        [
          {
            title: 'Twitter: Future of Artificial Intelligence',
            content: 'Explored the evolving landscape of AI, discussing breakthroughs, ethical considerations, and the societal impact of artificial intelligence.',
            link: 'https://twitter.com/360CP/status/1713812785589633224',
            timestamp: '2023-11-15T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Tech and Sustainability',
            content: 'Shared insights on the intersection of technology and sustainability, highlighting eco-friendly innovations and green tech initiatives.',
            link: 'https://twitter.com/360CP/status/1702300362466631876',
            timestamp: '2023-11-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: NFTs and Digital Art',
            content: 'Discussed the rise of NFTs in the digital art space, exploring the impact on the art market and the future of tokenized creativity.',
            link: 'https://twitter.com/360CP/status/1685921764568362496',
            timestamp: '2023-10-15T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Cybersecurity Awareness',
            content: 'Raised awareness about cybersecurity best practices, sharing tips and insights to stay secure in an increasingly digital world.',
            link: 'https://twitter.com/360CP/status/1672058390324566784',
            timestamp: '2023-10-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Robotics and Automation',
            content: 'Shared insights on the latest developments in robotics and automation, discussing advancements in AI-driven robotics and their applications.',
            link: 'https://twitter.com/360CP/status/1595678564225644544',
            timestamp: '2023-07-01T09:03:00.000Z',
            type: 'Twitter'
          },
       ],
       [
          {
            title: 'Twitter: Augmented Reality Trends',
            content: 'Explored the latest trends in augmented reality, discussing applications in gaming, education, and immersive experiences.',
            link: 'https://twitter.com/360CP/status/1659207180917328896',
            timestamp: '2023-09-15T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Future of Space Exploration',
            content: 'Shared thoughts on the exciting future of space exploration, including upcoming missions, technological advancements, and potential discoveries.',
            link: 'https://twitter.com/360CP/status/1647324056723456512',
            timestamp: '2023-09-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: Women in Tech Achievements',
            content: 'Celebrated the achievements of women in the tech industry, recognizing their contributions to innovation, leadership, and diversity.',
            link: 'https://twitter.com/360CP/status/1634402734012346880',
            timestamp: '2023-08-15T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: 5G and Connectivity',
            content: 'Explored the impact of 5G technology on connectivity, discussing its implications for communication, IoT, and the future of networks.',
            link: 'https://twitter.com/360CP/status/1621481340421232640',
            timestamp: '2023-08-01T09:03:00.000Z',
            type: 'Twitter'
          },
          {
            title: 'Twitter: The Metaverse Revolution',
            content: 'Explored the emerging concept of the metaverse, discussing its potential impact on industries, social interactions, and immersive experiences.',
            link: 'https://twitter.com/360CP/status/1608559946829208576',
            timestamp: '2023-07-15T09:03:00.000Z',
            type: 'Twitter'
          },
        ]
      ];

      // Determine the number of Twitter insights to display randomly
     
      const selectedTwitterListIndex = Math.floor(Math.random() * twitterLists.length);
      const selectedTwitterList = twitterLists[selectedTwitterListIndex];
      const numberOfTwitterInsightsToShow = Math.floor(Math.random() * (selectedTwitterList.length + 1));
      const shuffledTwitterList = selectedTwitterList.sort(() => Math.random() - 0.5);
      const selectedTwitterInsights = shuffledTwitterList.slice(0, numberOfTwitterInsightsToShow);

      // insert at random position
      insights.splice(Math.floor(Math.random() * (insights.length + 1)), 0, ...selectedTwitterInsights);
      insights.splice(Math.floor(Math.random() * 3), 0, insight1)
      insights.splice(Math.floor(Math.random() * 5), 0, insight2)
      insights.splice(Math.floor(Math.random() * 7), 0, insight3)   
      

      // for each insight with 'linkedin.com' in 'link' set type to 'LinkedIn'
      insights = insights.map((item) => {
        if (item.link && item.link.includes('linkedin.com')) {
          item.type = 'LinkedIn'
        }
        return item
      })

      return insights
    },

    updateInsights() {
      this.loadingInsights = true
      this.$store.dispatch('UPDATE_CONTACT_INSIGHTS', this.contact)
        .then((response) => {
          this.contact = response.data
          this.poll()
        })
    },

    poll() {
      this.pollTimer = setTimeout(() => {
        this.load()
          .then((response) => {
            this.contact = response.data
            if (this.contact.insights_loading) {
              this.poll()
            } else {
              this.loadingInsights = false
            }
          })
      }, 5000)
    },

    goAddNote() {
      this.isAddingNote = true
      this.note = {
        contact: this.contactId
      }
    },

    goEditNote(note) {
      this.isAddingNote = false
      this.note = note
    },

    goCancelEditNote() {
      this.isAddingNote = false
      this.note = {}
    },

    submitNote() {
      this.isSubmittingNote = true
      this.noteErrors = {}

      const action = this.note.id ? 'UPDATE_CONTACT_NOTE' : 'CREATE_CONTACT_NOTE'

      this.$store.dispatch(action, this.note)
        .then(() => {
          this.note = {}
          this.isAddingNote = false
          this.$store.dispatch('LOAD_CONTACT_NOTES', this.contactId)
            .then((response) => {
              this.notes = response.data
            })
        })
        .catch((error) => {
          this.errors = error.response.data
        })
        .finally(() => {
          this.isSubmittingNote = false
        })
    },

    goDeleteNote(note) {
      if (confirm('Are you sure you want to delete this note?')) {
        this.$store.dispatch('DELETE_CONTACT_NOTE', note)
          .then(() => {
            this.$store.dispatch('LOAD_CONTACT_NOTES', this.contactId)
              .then((response) => {
                this.notes = response.data
              })
          })
      }
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },

    getInsightTopic(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'LinkedIn Post'
        }
        if (item.type.includes('Podcast')) {
          return 'Podcast'
        }
        if (item.type.includes('Newsletter')) {
          return 'Newsletter'
        }
        if (item.type.includes('Twitter')) {
          return 'Twitter'
        }
      }
      return 'Webpage'
    },

    getInsightClass(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger'
        }
        if (item.type.includes('Newsletter')) {
          return 'text-success'
        }
        if (item.type.includes('Twitter')) {
          return 'text-primary'
        }
      }
      return 'text-default'
    },

    getInsightIconClasses(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info bg-info-lighten mdi-linkedin'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger bg-danger-lighten mdi-podcast'
        }
        if (item.type.includes('Newsletter')) {
          return 'text-success bg-success-lighten mdi-gmail'
        }
        if (item.type.includes('Twitter')) {
          return 'text-primary bg-primary-lighten mdi-twitter'
        }
      }
      return 'text-default bg-primary-lighten mdi-web'
    }

  }
}
</script>
