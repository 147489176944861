import axios from 'axios'

const state = {
  captchaToken: null,
  isLoggedIn: false,
  token: null,
  user: null,
  siteCompany: null,
  replyIoKey: null,
  gptPrompt: null,
  invitations: [],
  hasUploadedFile: false,
}

const actions = {
  REGISTER ({ commit, dispatch }, { user, invitationKey }) {
    return new Promise((resolve, reject) => {
      axios.post(import.meta.env.VITE_API_URL + 'register/' + invitationKey, user)
        .then((response) => {
          commit('SET_TOKEN', response.data.api_token.key)
          commit('SET_USER', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOGIN ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      axios.post(import.meta.env.VITE_API_URL + 'auth/', user)
        .then((response) => {
          commit('SET_TOKEN', response.data.token)
          dispatch('LOAD_USER')
            .then(resolve)
            .catch(reject)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_USER ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}me/`)
        .then((response) => {
          commit('SET_USER', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_SITE_COMPANY ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}site_company/`)
        .then((response) => {
          commit('SET_SITE_COMPANY', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPDATE_USER ({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      axios.put(`${import.meta.env.VITE_API_URL}me/`, user)
        .then((response) => {
          commit('SET_USER', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  PASSWORD_RESET ({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}me/password/reset/`, user)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  PASSWORD_CHANGE ({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}me/password/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_EMAIL_ACCOUNTS ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}email_engine_account/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  CREATE_EMAIL_ACCOUNT ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}email_engine_account/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SYNC_EMAIL_ACCOUNT ({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}email_account_messages/${id}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  DELETE_EMAIL_ACCOUNT ({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}email_account/${id}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_REPLY_IO_KEY ({ commit, state }, startupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}me/replyio-user/${startupId}/`)
        .then((response) => {
          commit('SET_REPLY_IO_KEY', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  
  UPDATE_REPLY_IO_KEY ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}me/replyio-user/`, payload)
        .then((response) => {
          commit('SET_REPLY_IO_KEY', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_GPT_PROMPT ({ commit, state }, { promptType, startupId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}gpt_prompt/${promptType}/${startupId}/`)
        .then((response) => {
          commit('SET_GPT_PROMPT', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPDATE_GPT_PROMPT ({ commit, state }, { payload, startupId }) {
    return new Promise((resolve, reject) => {
      axios.patch(`${import.meta.env.VITE_API_URL}gpt_prompt/${payload.prompt_type}/${startupId}/`, payload)
        .then((response) => {
          commit('SET_GPT_PROMPT', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_INVITATIONS ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}me/invitations/`)
        .then((response) => {
          commit('SET_INVITATIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  CREATE_INVITATION ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}me/invitations/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOGOUT ({ commit, dispatch }) {
    commit('LOGOUT')
    commit('CLEAR_STARTUPS')
    commit('CLEAR_INVESTORS')
    commit('CLEAR_ASTEL_INVESTORS')
    commit('CLEAR_OUTREACH')
  }
}

const getters = {
  authToken: state => {
    return state.token
  }
}

const mutations = {
  SET_TOKEN (state, payload) {
    state.token = payload
    state.isLoggedIn = true
    axios.defaults.headers.common['Authorization'] = `Token ${payload}`
  },

  SET_USER (state, payload) {
    state.user = payload
    state.isLoggedIn = true
  },

  SET_SITE_COMPANY (state, payload) {
    state.siteCompany = payload
  },

  SET_REPLY_IO_KEY (state, payload) {
    state.replyIoKey = payload
  },

  SET_GPT_PROMPT (state, payload) {
    state.gptPrompt = payload
  },

  SET_INVITATIONS (state, payload) {
    state.invitations = payload
  },

  SET_HAS_UPLOADED_FILE (state, payload) {
    state.hasUploadedFile = payload
  },

  LOGOUT (state) {
    state.isLoggedIn = false
    state.token = null
    state.user = null
    state.siteCompany = null
    state.invitations = []
    axios.defaults.headers.common['Authorization'] = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
